import React from 'react';
import { Accordion } from 'lib/Accordion';
import colors from 'lib/colors';
import Select from 'react-virtualized-select';

import { Address } from '../settings/types';
import { formatAddress } from '../settings/helpers';
import { AddressesByID } from './requests';

const HeaderExpansionItem = ({
  address,
}: {
  address: Address;
}): JSX.Element => {
  return (
    <div
      className="container-fluid"
      style={styles.expansionContainer}
    >
      <div className="row">
        <div className="col-sm-6 no-padding-left">
          {address.shortAddress}
          <br />
          {address.postalCode} {address.city}
        </div>
        <div className="col-sm-6 no-padding-left">
          {address.phone}
          <br />
          {address.email}
        </div>
      </div>
    </div>
  );
};

const getHeaderExpansionItem = (address: Address): JSX.Element => (
  <HeaderExpansionItem address={address} />
);

type Props = {
  addresses: Address[];
  addressesByID: AddressesByID;
  chosenAddressID: string | null;
  setChosenAddressID: (val: string | null) => any;
  isExpanded: boolean;
  transl: (label: string) => string;
};

export const StartAddressPanel = ({
  addresses,
  addressesByID,
  chosenAddressID,
  setChosenAddressID,
  isExpanded,
  transl,
}: Props): JSX.Element => {
  const headerExpansionItem =
    chosenAddressID !== null && !isExpanded
      ? getHeaderExpansionItem(addressesByID[chosenAddressID])
      : null;

  const titleTextAlign =
    chosenAddressID !== null && !isExpanded ? 'left' : 'center';

  const dropdownOptions = addresses.map((item) => ({
    label: formatAddress(item),
    value: item.addressID,
  }));
  const selectedDropdownOption: any = chosenAddressID
    ? {
        label: formatAddress(addressesByID[chosenAddressID]),
        value: chosenAddressID,
      }
    : null;

  return (
    <Accordion
      titleTextAlign={titleTextAlign}
      isExpanded={isExpanded}
      title={transl('PickupAddress')}
      imgUrl={'/img/sender.png'}
      headerExpansionItem={headerExpansionItem}
      accordionExtraClassName={''}
    >
      <div className="container-fluid" style={{ padding: '15px' }}>
        <div className="row">
          <div className="col-xs-12">
            <div className="form-group">
              <label>{transl('ChooseAddress')}</label>
              <Select
                placeholder={transl('ChooseAddress')}
                options={dropdownOptions}
                onChange={(newVal: any): void => {
                  setChosenAddressID(newVal?.value || null);
                }}
                value={selectedDropdownOption}
              />
            </div>
          </div>
        </div>
      </div>
    </Accordion>
  );
};

const styles = {
  expansionContainer: {
    paddingLeft: '75px',
    paddingRight: '75px',
    margin: 0,
  },
  errorMsg: {
    color: colors.danger,
    whiteSpace: 'pre-wrap' as 'pre-wrap',
  },
};
