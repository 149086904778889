import React from 'react';
import FixedTable from 'lib/FixedTable';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import moment from 'lib/moment';
import { DateSearchBox } from 'lib/DateSearchBox';

import { TimeslotPopup } from './TimeslotPopup';
import Gateway from '../web/gateway';
import { TransportOrder } from './types';
import { IsAdminContext } from '../contexts';
import { featureGateInUse, featureGates } from '../featureGates';
import { currencyToSymbol } from '../order/helpers';

const formatTimestamp = (stamp: string): string =>
  moment(stamp).format('L') + ' ' + moment(stamp).format('HH:mm');

const formatAddress = (
  title: string,
  shortAddress: string,
  postalCode: string
): string => `${title} (${shortAddress}, ${postalCode})`;

const formatSum = (
  price: number,
  transl: (label: string) => string,
  currency: string
): string => {
  const priceFormatted =
    price && currency === 'EUR'
      ? (price / 100)
          .toFixed(2)
          .split('.')
          .join(transl('DecimalSeparator'))
      : price?.toString();

  return `${priceFormatted} ${currencyToSymbol(currency)}`;
};

const orderCanBeModified = (order: TransportOrder): boolean => {
  return (
    !!order.earliestPickupTime &&
    order.state === 'orderPlaced' &&
    moment(order.earliestPickupTime)
      .subtract(1, 'hours')
      .isAfter(moment())
  );
};

const orderCanBeCancelled = (order: TransportOrder): boolean => {
  return order.state === 'orderPlaced';
};

type HistoryListItemProps = {
  attrs: TransportOrder;
  transl: (label: string) => string;
  openTimeslotPopup: () => any;
  key: any;
};

const HistoryListItem = ({
  attrs,
  transl,
  openTimeslotPopup,
}: HistoryListItemProps): JSX.Element => {
  const isAdmin = React.useContext(IsAdminContext);
  const shouldShowPrices =
    isAdmin ||
    !featureGateInUse(featureGates.hidePricesFromNonAdmins);

  return (
    <tr>
      <td>{attrs.externalID}</td>
      <td>
        {transl(attrs.state)}
        <br />
        {attrs.state === 'courierChosen' &&
          !!attrs.pickupTrackingLink && (
            <button
              className="btn btn-primary btn-sm"
              onClick={(): void => {
                window.open(attrs.pickupTrackingLink, '_blank');
              }}
            >
              {transl('Tracking')}
            </button>
          )}
        <br />
        {attrs.myXLineCourierName && attrs.myXLineCourierPhone && (
          <div>
            <b>{transl('CourierInformation')}:</b>
            <br />
            {attrs.myXLineCourierName} <br />
            {attrs.myXLineCourierPhone}
          </div>
        )}
      </td>
      <td>{formatTimestamp(attrs.orderPlacedTimestamp)}</td>
      <td>
        {formatAddress(
          attrs.startAddressTitle,
          attrs.startAddress,
          attrs.startPostalCode
        )}
      </td>
      <td>
        {formatAddress(
          attrs.recipientName,
          attrs.recipientAddress,
          attrs.recipientPostalCode
        )}
      </td>
      <td>
        {shouldShowPrices
          ? formatSum(
              attrs.priceForOrderProvider,
              transl,
              attrs.currency
            )
          : '-'}
      </td>
      <td>
        {!!attrs.earliestPickupTime
          ? formatTimestamp(attrs.earliestPickupTime)
          : transl('Immediately')}
        <br />
        {orderCanBeCancelled(attrs) && (
          <span
            style={{ paddingLeft: '0px' }}
            className="btn btn-link"
            onClick={openTimeslotPopup}
          >
            {orderCanBeModified(attrs)
              ? transl('ModifyOrCancel')
              : transl('CancelOrder')}
          </span>
        )}
      </td>
    </tr>
  );
};

export const HistoryPage = ({
  transl,
}: {
  transl: (label: string) => string;
}): JSX.Element => {
  const [startDate, setStartDate] = React.useState(
    moment().subtract(3, 'days').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );

  const [orders, setOrders] = React.useState(
    null as TransportOrder[] | null
  );

  const [popupAttrs, setPopupAttrs] = React.useState(
    null as TransportOrder | null
  );

  const doSearch = (start: string, end: string): void => {
    setOrders(null);
    Gateway.get(
      `/providercompany/orders?start=${start}&end=${end}`
    ).then((data) =>
      setOrders(
        data.filter((item: any) => item.priceForOrderProvider > 0)
      )
    );
  };

  React.useEffect(() => {
    doSearch(
      moment().subtract(3, 'days').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD')
    );
    return (): void => {
      // Intentionally empty
    };
  }, []);

  const searchPressed = (): void => doSearch(startDate, endDate);

  return (
    <div style={commonStyles.flexColumnContainer}>
      <DateSearchBox
        startDate={startDate}
        endDate={endDate}
        startDateChanged={setStartDate}
        endDateChanged={setEndDate}
        searchPressed={searchPressed}
        transl={transl}
      />
      {orders === null && <LoadingComponent />}
      {orders !== null && (
        <div style={commonStyles.defaultContainer}>
          <FixedTable
            header={
              <tr>
                <th>{transl('OrderNumber')}</th>
                <th>{transl('State')}</th>
                <th>{transl('OrderPlacedTimestamp')}</th>
                <th>{transl('PickupAddress')}</th>
                <th>{transl('Recipient')}</th>
                <th>{transl('PriceWithoutVat')}</th>
                <th>{transl('EarliestPickupTime')}</th>
              </tr>
            }
          >
            {orders.map((attrs, i) => {
              return (
                <HistoryListItem
                  key={i}
                  attrs={attrs}
                  transl={transl}
                  openTimeslotPopup={(): void => {
                    if (orderCanBeModified(attrs)) {
                      setPopupAttrs(attrs);
                    } else if (orderCanBeCancelled(attrs)) {
                      if (
                        !window.confirm(transl('ConfirmDeleteOrder'))
                      ) {
                        return;
                      }
                      setOrders(null);
                      Gateway.del(
                        `/providercompany/orders/id/${attrs.transportOrderId}/cancel`
                      )
                        .catch(() => {
                          alert(transl('ErrorRemovingOrder'));
                        })
                        .finally(() => {
                          searchPressed();
                        });
                    }
                  }}
                />
              );
            })}
          </FixedTable>
        </div>
      )}
      {!!popupAttrs && (
        <TimeslotPopup
          transl={transl}
          closePopup={(): void => setPopupAttrs(null)}
          attrs={popupAttrs}
          reloadOrders={searchPressed}
        />
      )}
    </div>
  );
};
