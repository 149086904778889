import React from 'react';
import { Accordion } from 'lib/Accordion';
import colors from 'lib/colors';
import Select from 'react-virtualized-select';
import { isEqual } from 'lodash';
import { featureGateInUse, featureGates } from '../featureGates';

import { Address } from '../settings/types';
import {
  formatAddress,
  getCityForPostalcode,
} from '../settings/helpers';
import { AddressSavingType } from './types';
import { commonStyles } from 'lib/commonStyles';

const HeaderExpansionItem = ({
  address,
}: {
  address: Address;
}): JSX.Element => {
  return (
    <div
      className="container-fluid"
      style={styles.expansionContainer}
    >
      <div className="row">
        <div className="col-sm-6 no-padding-left">
          {address.shortAddress}
          <br />
          {address.postalCode} {address.city}
        </div>
        <div className="col-sm-6 no-padding-left">
          {address.phone}
          <br />
          {address.email}
        </div>
      </div>
    </div>
  );
};

const getHeaderExpansionItem = (address: Address): JSX.Element => (
  <HeaderExpansionItem address={address} />
);

const emptyAddress: Address = {
  title: '',
  shortAddress: '',
  postalCode: '',
  notes: '',
  phone: '',
  email: null,
};

type Props = {
  values: Address | null;
  isExpanded: boolean;
  title: string;
  imgUrl: string;
  addresses: Address[];
  setValues: (
    arg: Address | ((prev: Address | null) => Address)
  ) => void;
  transl: (label: string) => string;
  error: string | null;
  panelID: string;
  isStartAddress: boolean;
  addressSavingType: AddressSavingType;
  setAddressSavingType: (newType: AddressSavingType) => void;
  showSynlabEndAddressPanel?: () => void;
  isAdmin?: boolean;
};

const stripPhoneNo = (phone: string): string => {
  const allowedChars = '+0123456789- ';
  let res = '';
  for (const c of phone) {
    if (allowedChars.includes(c)) res += c;
  }
  return res;
};

export const AddressPanel = ({
  values,
  isExpanded,
  title,
  imgUrl,
  addresses,
  setValues,
  transl,
  error,
  panelID,
  isStartAddress,
  addressSavingType,
  setAddressSavingType,
  showSynlabEndAddressPanel,
  isAdmin,
}: Props): JSX.Element => {
  const [addressFromDropdown, setAddressFromDropdown] =
    React.useState(emptyAddress);

  const headerExpansionItem =
    values !== null && !isExpanded
      ? getHeaderExpansionItem(values)
      : null;

  const panelTitle =
    values !== null && !isExpanded ? values.title : title;

  const titleTextAlign =
    values !== null && !isExpanded ? 'left' : 'center';

  const formValues = values || addressFromDropdown;

  const setVal = (key: string, val: string | undefined): void => {
    let value = val;
    if (key === 'phone' && val) {
      value = stripPhoneNo(val);
    }
    setValues((prev: Address | null) => {
      const vals = prev || formValues;
      return {
        ...vals,
        [key]: value,
      } as Address;
    });
  };

  const setPostalcode = (inp: string): void => {
    const newPostalcode = inp.trim();
    setVal('postalCode', newPostalcode);
    if (newPostalcode.length >= 5) {
      getCityForPostalcode(newPostalcode).then((city) =>
        setVal('city', city)
      );
    } else {
      setVal('city', undefined);
    }
  };

  const dropdownOptions = addresses.map((item) => ({
    label: formatAddress(item),
    value: JSON.stringify(item),
  }));
  const selectedDropdownOption: any = addressFromDropdown.addressID
    ? {
        label: formatAddress(addressFromDropdown),
        value: JSON.stringify(addressFromDropdown),
      }
    : null;

  const formValuesModified = !isEqual(
    formValues,
    addressFromDropdown
  );

  return (
    <Accordion
      titleTextAlign={titleTextAlign}
      isExpanded={isExpanded}
      title={panelTitle}
      imgUrl={imgUrl}
      headerExpansionItem={headerExpansionItem}
      accordionExtraClassName={
        featureGateInUse(featureGates.synlabCustomizations)
          ? 'accordion-synlab'
          : ''
      }
    >
      <div className="container-fluid" style={{ padding: '15px' }}>
        <div className="row">
          <div className="col-xs-12">
            <div className="form-group">
              <label htmlFor={`${panelID}-chooseOrEnterAddress`}>
                {transl('ChooseOrEnterAddress')}
              </label>
              <Select
                placeholder={transl('NewAddress')}
                options={dropdownOptions}
                onChange={(newVal: any): void => {
                  const chosen: Address = newVal
                    ? JSON.parse(newVal.value)
                    : emptyAddress;

                  setAddressFromDropdown(chosen);
                  setValues(chosen);
                }}
                value={selectedDropdownOption}
              />
            </div>
            {error && <h5 style={styles.errorMsg}>{error}</h5>}
            {!error && (
              <h5>{transl('AsteriskMarkedFieldsAreMandatory')}</h5>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor={`${panelID}-title`}>
                {transl(
                  isStartAddress ? 'SenderName' : 'RecipientName'
                )}
                *
              </label>
              <input
                type="text"
                value={formValues.title}
                className="form-control"
                id={`${panelID}-title`}
                onChange={(e): void =>
                  setVal('title', e.target.value)
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor={`${panelID}-shortAddress`}>
                {transl('ShortAddress')}*
              </label>
              <input
                type="text"
                value={formValues.shortAddress}
                className="form-control"
                id={`${panelID}-shortAddress`}
                onChange={(e): void =>
                  setVal('shortAddress', e.target.value)
                }
              />
            </div>
            <div style={commonStyles.flexRowContainer}>
              <div className="form-group">
                <label htmlFor={`${panelID}-postalCode`}>
                  {transl('PostalCode')}*
                </label>
                <input
                  type="text"
                  value={formValues.postalCode}
                  className="form-control"
                  id={`${panelID}-postalCode`}
                  onChange={(e): void =>
                    setPostalcode(e.target.value)
                  }
                />
              </div>
              <div
                className="form-group"
                style={{ paddingLeft: '20px' }}
              >
                <label>{transl('City')}</label>
                <h4>{formValues.city}</h4>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor={`${panelID}-notes`}>
                {transl('ArrivalInstructions')}
              </label>
              <textarea
                value={formValues.notes}
                className="form-control"
                id={`${panelID}-notes`}
                onChange={(e): void =>
                  setVal('notes', e.target.value)
                }
              />
            </div>
            {showSynlabEndAddressPanel &&
              isAdmin &&
              featureGateInUse(featureGates.synlabCustomizations) && (
                <tr>
                  <td colSpan={2}>
                    <button
                      type="button"
                      onClick={(): void =>
                        showSynlabEndAddressPanel()
                      }
                      className={'btn btn-primary'}
                    >
                      {transl('UseDefaultAddress')}
                    </button>
                  </td>
                </tr>
              )}
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor={`${panelID}-phone`}>
                {transl('PhoneNumber')}*
              </label>
              <input
                type="text"
                value={formValues.phone}
                className="form-control"
                id={`${panelID}-phone`}
                onChange={(e): void =>
                  setVal('phone', e.target.value)
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor={`${panelID}-email`}>
                {transl('Email')}
                {!isStartAddress && '*'}
              </label>
              <input
                type="text"
                value={formValues.email || ''}
                className="form-control"
                id={`${panelID}-email`}
                onChange={(e): void =>
                  setVal('email', e.target.value)
                }
              />
            </div>
            {formValuesModified && (
              <div>
                {!!formValues.addressID && (
                  <div className="form-group">
                    <label>
                      <input
                        type="checkbox"
                        checked={addressSavingType === 'modify'}
                        onChange={(e): void =>
                          setAddressSavingType(
                            (e.target as any).checked
                              ? 'modify'
                              : null
                          )
                        }
                      />
                      &nbsp;&nbsp;
                      {transl('SaveAddressMoification')}
                    </label>
                  </div>
                )}
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      checked={addressSavingType === 'new'}
                      onChange={(e): void =>
                        setAddressSavingType(
                          (e.target as any).checked ? 'new' : null
                        )
                      }
                    />
                    &nbsp;&nbsp;
                    {transl(
                      !formValues.addressID
                        ? 'SaveToAddressRegister'
                        : 'SaveAsNewAddress'
                    )}
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Accordion>
  );
};

const styles = {
  expansionContainer: {
    paddingLeft: '75px',
    paddingRight: '75px',
    margin: 0,
  },
  errorMsg: {
    color: colors.danger,
    whiteSpace: 'pre-wrap' as 'pre-wrap',
  },
};
