const fi: any = {
  DecimalSeparator: ',',
  EnterCompanyBid:
    "Aloita syöttämällä yrityksen y-tunnus ja klikkaamalla 'Siirry kirjautumaan'",
  CompanyBid: 'Y-tunnus',
  CompanyNotFoundError:
    'Yritystä annetulla y-tunnuksella ei löytynyt. Ole hyvä ja yritä uudelleen.',
  GoToLogin: 'Siirry kirjautumaan',
  LoginToCompany: 'Kirjaudu sisään yritykseen #COMPANY#',
  Email: 'Sähköposti',
  Password: 'Salasana',
  Login: 'Kirjaudu sisään',
  IncorrectCredentialsError:
    'Kirjautuminen epäonnistui. Tarkista syöttämäsi tunnukset ja yritä uudelleen',
  TransportOrderHistory: 'Tilaushistoria',
  TransportOrder: 'Uusi tilaus',
  orderPlaced: 'Odottaa kuljetusta',
  courierChosen: 'Kuljettaja valittu',
  pickedUp: 'Kuljetuksessa',
  delivered: 'Luovutettu vastaanottajalle',
  pickupFailed: 'Nouto epäonnistui',
  deliveryFailed: 'Toimitus epäonnistui',
  cancelled: 'Peruutettu',
  OrderPlacedTimestamp: 'Tilaus jätetty',
  CourierChosenTimestamp: 'Kuljettaja valittu',
  PickedUpTimestamp: 'Otettu kuljetukseen',
  DeliveredTimestamp: 'Luovutettu vastaanottajalle',
  DeliveryTimestamp: 'Toimitusaika',
  State: 'Tila',
  FromAddress: 'Lähtöpaikka',
  ToAddress: 'Päämäärä',
  DistanceinKm: 'Etäisyys (km)',
  WriteSearchDates: 'Syötä aikaväli',
  DoSearch: 'Tee haku',
  Phone: 'Puhelin',
  Language: 'Kieli',
  SumWithoutVat: 'Veroton',
  Vat: 'Alv',
  SumWithVat: 'Verollinen',
  Courier: 'Kuljettaja',
  Date: 'Päiväys',
  Area: 'Alue',
  SumWithoutVatWithExplanation: 'Summa (Veroton)',
  Timestamp: 'Aikaleima',
  OrderNumber: 'Tilausnumero / Tilauksen tunniste',
  CourierInformation: 'Kuljettajan tiedot',
  OrganizationID: 'Organisaatiotunnus',
  CurrentVehicle: 'Ajoneuvon tiedot',
  Yes: 'Kyllä',
  No: 'Ei',
  PickupPlaceInformation: 'Noutopaikan tiedot',
  CustomerInformation: 'Asiakkaan tiedot',
  Address: 'Osoite',
  DeliveryInstructions: 'Toimitusohjeet',
  PickupInstructions: 'Nouto-ohjeet',
  OrderInformation: 'Tilauksen tiedot',
  ReturnAddressInformation: 'Palautusosoitteen tiedot',
  PickupFailReason: 'Epäonnistumisen syy',
  cantFindPickupSpot:
    'Noutopaikkaa ei löytynyt eikä noutopaikan puhelimeen vastattu',
  cantGetToPickupSpot: 'Noutopaikka löytyi mutta sinne ei pääse',
  packageInvalid: 'Paketti ei täytä Cabappin ehtoja',
  packageNotAvailable: 'Paketti ei ollut valmis kuskin saapuessa',
  FailureReason: 'Epäonnistumisen kuvaus',
  NumberOfPackages: 'Pakettien lukumäärä',
  ContactlessDelivery: 'Kontaktiton toimitus',
  Events: 'Tapahtumat',
  ReturnedTimestamp: 'Palautettu lähettäjälle',
  Refresh: 'Päivitä',
  Monitoring: 'Monitorointi',
  Settings: 'Asetukset',
  PersonalSettings: 'Käyttäjän asetukset',
  AddressRegister: 'Osoiterekisteri',
  UserRegister: 'Käyttäjärekisteri',
  PasswordChangeSuccess: 'Salasana vaihdettu onnistuneesti.',
  PasswordChangeFailure:
    'Salasanan vaihtaminen epäonnistui. Tarkista että syötit vanhan salasanan oikein ja yritä uudelleen.',
  ChangePassword: 'Vaihda salasana',
  OldPassword: 'Vanha salasana',
  NewPassword: 'Uusi salasana',
  NewPassword2: 'Uusi salasana uudelleen',
  ConfirmDemoteUser:
    'Vahvista käyttäjän #EMAIL# admin-oikeuksien poistaminen',
  ConfirmPromoteUser:
    'Vahvista käyttäjän #EMAIL# korottaminen adminiksi',
  ConfirmResetUser:
    'Vahvista käyttäjän #EMAIL# salasanan nollaaminen ja uuden salasanan lähettäminen sähköpostitse',
  ResetUserSuccess:
    'Käyttäjän #EMAIL# salasana on nyt lähetetty sähköpostitse',
  ResetUserFailure:
    'Käyttäjän #EMAIL# salasanan nollaaminen epäonnistui. Yritä myöhemmin uudelleen',
  ConfirmDeleteUser:
    'Vahvista käyttäjän #EMAIL# kirjautumistunnusten poistaminen lopullisesti',
  DeleteUserSuccess: 'Käyttäjä #EMAIL# poistettu onnistuneesti',
  DeleteUserFailure:
    'Käyttäjän #EMAIL# poistaminen epäonnistui. Yritä myöhemmin uudelleen.',
  DemoteUser: 'Poista admin-oikeudet',
  PromoteUser: 'Korota adminiksi',
  ResetUser: 'Nollaa salasana',
  DeleteUser: 'Poista käyttäjä',
  NewUser: 'Uusi käyttäjä',
  IsAdmin: 'Admin-oikeudet',
  InviteUser: 'Kutsu uusi käyttäjä',
  SendInvite: 'Lähetä kutsu',
  ClosePopup: 'Sulje lomake',
  InviteSendSuccess: 'Kutsu lähetettu onnistuneesti',
  InviteSendFailure:
    'Uuden käyttäjän kutsuminen epäonnistui. Yritä myöhemmin uudelleen.',
  AddressFailureMsg: 'Syötetystä osoitteesta löytyi ongelmia:\n',
  AddressFailureGeocoding:
    'Syötettyyn osoitteeseen ei löydetty reittiohjeita. Tarkista syöttämäsi osoite ja postinumero kirjoitusvirheiden varalta.',
  AddressFailurePostalcode:
    'Osoite ei vaikuta olevan organisaatiosi ja Cabappin keskenään sopimalla toiminta-alueella. Ota yhteys Cabappiin aloittaaksesi toimitukset myös syöttämältäsi postinumeroalueelta.',
  NewAddress: 'Uusi osoite',
  ModifyAddress: 'Muokkaa osoitteen tietoja',
  AddressTitle: 'Nimi/Otsikko',
  ShortAddress: 'Lähiosoite',
  PostalCode: 'Postinumero',
  City: 'Postitoimipaikka',
  PhoneNumber: 'Puhelinnumero',
  ArrivalInstructions: 'Saapumisohjeet',
  DeleteAddress: 'Poista osoite',
  Save: 'Tallenna',
  ConfirmDeleteAddress: 'Vahvista osoitteen poistaminen lopullisesti',
  AsteriskMarkedFieldsAreMandatory:
    'Tähdellä (*) merkityt kentät ovat pakollisia',
  PickupAddress: 'Noutopaikka',
  ChooseOrEnterAddress:
    'Valitse osoite pudotusvalikosta tai syötä osoitteen tiedot',
  Previous: 'Takaisin',
  Next: 'Jatka',
  SenderName: 'Lähettäjän nimi',
  RecipientName: 'Vastaanottajan nimi',
  SaveToAddressRegister: 'Tallenna osoiterekisteriin',
  SaveAddressMoification: 'Tallenna osoitteenmuutokset',
  SaveAsNewAddress: 'Tallenna uutena osoitteena',
  Recipient: 'Vastaanottaja',
  Order: 'Toimitus',
  OrderNumberInvalidMsg: 'Syöttämäsi tilaustunniste on jo käytetty.',
  OrderNumberValidMsg: 'Tarkistettu',
  SendOrder: 'Lähetä Kuljetustilaus',
  UnknownReason: 'Syy ei tiedossa',
  OrderSendingFailed:
    'Kuljetustilauksen lähettäminen epäonnistui. Epäonnistumisen syy: ',
  PhoneNumberMalformed: 'Tarkista syöttämäsi puhelinnumero',
  PriceWithoutVat: 'Hinta (Ei sis. Alv)',
  NofPackages: 'Pakettien määrä: ',
  OrderPickupTime: 'Nouto aikaisintaan',
  Immediately: 'Heti',
  SetLaterTime: 'Aseta myöhempi ajankohta',
  Today: 'Tänään',
  Time: 'Aika',
  TimedOrderWarning:
    'Lähettämällä ajoitetun tilauksen hyväksyt olevasi vastuussa siitä, että tilaus on pakattu ja valmis noudettavaksi syöttämäsi ajankohtana. Kuriirilla ei ole velvollisuutta odottaa keskeneräisen tilauksen pakkaamista.',
  EarliestPickupTime: 'Nouto aikaisintaan',
  ModifyOrCancel: 'Muokkaa tai peru',
  ModifyOrCancelOrder: 'Muokkaa kuljetustilauksen noutoaikaa',
  ErrorRemovingOrder: 'Valitettavasti tätä tilausta ei voitu perua',
  ErrorModifyingOrder:
    'Valitettavasti tätä tilausta ei voitu muokata',
  CancelOrder: 'Peru kuljetustilaus',
  ConfirmDeleteOrder:
    'Haluatko varmasti perua valitun kuljetustilauksen?',
  RoutineSamples: 'Rutiininäytteiden vastaanotto',
  CovidSamples: 'Koronanäytteiden vastaanotto',
  ChooseDoor: 'Valitse ovi',
  CouriersProvidedBy: 'Kuljetuspalvelun tarjoaa',
  Support9to17: 'Asiakaspalvelu 8-17',
  ChooseDifferentAddress: 'Valitse toinen osoite',
  UseDefaultAddress: 'Käytä oletusosoitetta',
  SundayExtra: 'Sunnuntailisä (Ei sis. Alv)',
  RouteName: 'Reitin nimi',
  ScheduledOrders: 'Säännölliset kuljetukset',
  ScheduledOrderEvents: 'Kuljetusaikataulu',
  NumberOfPallets: 'Lavojen lukumäärä',
  DeliveryType: 'Lähetyksen tyyppi',
  Normal: 'Normaali',
  Pallet: 'Teholava',
  EurPallet: 'EUR-lava',
  FinPallet: 'FIN-lava',
  ServiceLevel: 'Toimitusnopeus',
  ServiceLevelSamehour: 'Pikatoimitus (30-120min)',
  ServiceLevelSameday: 'Saman päivän toimitus (4h)',
  MoreThan3: 'Yli 3',
  SpecialOrders: 'Sopimuskuljetukset',
  ChooseSpecialOrder: 'Valitse reitti',
  SkipPincodeVerification:
    'Älä vaadi PIN-koodia toimituksen yhteydessä',
  ReturnToStartAddress: 'Edestakainen kuljetus',
  ReturnToStartAddressWarningMsg:
    'HUOM! Jos tilaat edestakaisen kuljetuksen, veloitetaan paluutoimitus erillisenä kuljetuksena hinnastomme mukaan.',
  Tracking: 'Seuranta',
  ChooseAddress: 'Valitse Osoite',
  Remove: 'Poista',
  MultiAddressOrder: 'Jakelutilaus',
  DeliveryAddresses: 'Vastaanottajat',
  ReturnToStartAfterDelivery: 'Paluu noutopaikkaan jakelun jälkeen',
  ChosenAddresses: 'Valitut osoitteet',
  DeleteSelected: 'Poista valitut',
  ConfirmDeleteAddresses:
    'Haluatko varmasti poistaa valitut #NUM_ADDRESSES# osoitetta',
  ColdDelivery: 'Kylmäkuljetus',
  IsCriticallyUrgent: 'Kiireellinen kuljetus',
  OrderFlowerRolls: 'Kuljetus sisältää kukkarullakkoja',
  NumberOfFlowerRolls: 'Kukkarullakkojen lukumäärä',
  MyxlineContactDetails:
    'Cabapp on osa MyXLine-konsernia. Ongelmatilanteissa asiakaspalvelumme palvelee <b>ma-pe 8-17</b> numerossa <b>010 200 5000</b>. Jos asia liittyy yksittäiseen tilaukseen, voit <b>soittaa suoraan kuljettajalle</b>. Kuljettajan yhteystiedot näet <b>Tilaushistoria</b>-välilehdeltä.',
};

export default fi;
