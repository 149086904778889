import React from 'react';
import Modal from 'lib/Modal';
import { notify } from 'lib/Notifier';
import Gateway from '../web/gateway';
import { commonStyles } from 'lib/commonStyles';

export const NewUserPopup = ({
  transl,
  closePopup,
  reloadUsers,
  setIsLoading,
}: {
  transl: (label: string) => string;
  closePopup: () => void;
  reloadUsers: () => void;
  setIsLoading: (loading: boolean) => void;
}): JSX.Element => {
  const [email, changeEmail] = React.useState('');
  const [isAdmin, changeIsAdmin] = React.useState(false);

  const saveData = (): void => {
    if (!email) return;
    setIsLoading(true);
    Gateway.post(`/providercompany/users`, {
      data: {
        email: email.trim().toLowerCase(),
        isAdmin,
      },
    })
      .then(() => {
        setIsLoading(false);
        reloadUsers();
        notify(transl('InviteSendSuccess'));
      })
      .catch(() => {
        setIsLoading(false);
        notify(transl('InviteSendFailure'));
      });

    closePopup();
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: '400px',
      maxHeight: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <Modal
      transl={transl}
      isOpen={true}
      onRequestClose={closePopup}
      style={popupStyles}
      contentLabel={'yn0'}
      shouldCloseOnOverlayClick={true}
    >
      <div style={commonStyles.popupScrollContainer}>
        <h2>{transl('InviteUser')}</h2>
        <div className="form-group">
          <label htmlFor="newUserPopup-email">
            {transl('Email')}
          </label>
          <input
            type="text"
            value={email}
            className="form-control"
            id="newUserPopup-email"
            maxLength={50}
            onChange={(e): void =>
              changeEmail(e.target.value.toLowerCase())
            }
          />
        </div>
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              checked={isAdmin}
              onChange={(e): void =>
                changeIsAdmin((e.target as any).checked)
              }
            />
            &nbsp;&nbsp;{transl('IsAdmin')}
          </label>
        </div>
      </div>
      <div style={commonStyles.popupBtnContainer}>
        <div style={commonStyles.flexColumnContainer}>
          <button
            type="button"
            className="btn btn-primary btn-lg btn-block"
            onClick={saveData}
          >
            <i className="fa fa-floppy-o"></i>
            &nbsp;&nbsp;{transl('SendInvite')}
          </button>
        </div>
      </div>
    </Modal>
  );
};
