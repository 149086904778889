import moment from 'moment';
import momentTz from 'moment-timezone';

import { Address } from '../settings/types';
import {
  validateAddress,
  addressValidationToStr,
  formatAddressForSaving,
} from '../settings/helpers';
import { PricingData, AddressSavingType } from './types';

import Gateway from '../web/gateway';

const validatePhoneNo = (phone: string): boolean => {
  const allowedChars = '+0123456789';
  let res = 0;
  for (const c of phone) {
    if (allowedChars.includes(c)) res++;
  }
  return res > 8;
};

export const startAddressValidation = async (
  address: Address,
  transl: (label: string) => string
): Promise<string | null> => {
  if (
    !address.title ||
    !address.shortAddress ||
    !address.postalCode ||
    !address.phone
  ) {
    return transl('AsteriskMarkedFieldsAreMandatory');
  }

  if (!validatePhoneNo(address.phone)) {
    return transl('PhoneNumberMalformed');
  }

  const validationRes = await validateAddress(address, true);

  if (
    !validationRes.geocodingValid ||
    !validationRes.postalcodeValid
  ) {
    return addressValidationToStr(validationRes, transl);
  }
  return null;
};

export const recipientAddressValidation = async (
  address: Address,
  transl: (label: string) => string
): Promise<string | null> => {
  if (
    !address.title ||
    !address.shortAddress ||
    !address.postalCode ||
    !address.phone ||
    !address.email
  ) {
    return transl('AsteriskMarkedFieldsAreMandatory');
  }

  if (!validatePhoneNo(address.phone)) {
    return transl('PhoneNumberMalformed');
  }

  const validationRes = await validateAddress(address, false);

  if (
    !validationRes.geocodingValid ||
    !validationRes.postalcodeValid
  ) {
    return addressValidationToStr(validationRes, transl);
  }
  return null;
};

export const retrievePricingForJourney = async (
  start: Address,
  end: Address,
  earliestPickupTime: string,
  numPackages: number,
  packageType: 'normal' | 'pallet' | 'eurPallet' | 'finPallet',
  serviceLevel: 'samehour' | 'sameday'
): Promise<PricingData> => {
  const data = {
    startAddress: start.shortAddress,
    startPostalCode: start.postalCode,
    recipientAddress: end.shortAddress,
    recipientPostalCode: end.postalCode,
    earliestPickupTime,
    numPackages,
    packageType,
    serviceLevel,
  };
  return await Gateway.post('/providercompany/pricing', { data });
};

export const validateExternalID = async (
  externalID: string
): Promise<boolean> => {
  try {
    const res = await Gateway.post(
      '/providercompany/externalid/validate',
      { data: { externalID } }
    );
    return res.isValid;
  } catch (err) {
    return false;
  }
};

export const generateUniqueExternalID = (): string => {
  return moment().format('YYYYMMDD-HHmmss-SSS');
};

export const saveAddress = async (
  address: Address,
  savingType: AddressSavingType
): Promise<boolean> => {
  try {
    const data = formatAddressForSaving(address);
    if (savingType === null) return true;
    if (savingType === 'new') {
      await Gateway.post(`/providercompany/addresses`, {
        data,
      });
      return true;
    }
    if (savingType === 'modify' && address.addressID) {
      await Gateway.put(
        `/providercompany/addresses/id/${address.addressID}`,
        {
          data,
        }
      );
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

const getHelsinkiHour = () =>
  momentTz().locale('fi').tz('Europe/Helsinki').hour();

const getHelsinkiWeekday = () =>
  momentTz().locale('fi').tz('Europe/Helsinki').isoWeekday();

export const nowIsOutsideHelsinkiBusinessHours = () => {
  const helsinkiHour = getHelsinkiHour();
  if (helsinkiHour < 8 || helsinkiHour >= 18) return true;
  const isoWeekday = getHelsinkiWeekday();
  if (isoWeekday === 6 || isoWeekday === 7) return true;
  return false;
};

export const currencyToSymbol = (currency: string) => {
  if (currency === 'EUR') return '€';
  return currency;
};
