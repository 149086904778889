import React from 'react';
import FixedTable from 'lib/FixedTable';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import { Address } from './types';
import { formatAddress } from './helpers';
import { AddressPopup } from './AddressPopup';

import Gateway from '../web/gateway';

type AddressListItemProps = {
  attrs: Address;
  openPopup: () => void;
  selected: boolean;
  toggleSelection: () => any;
  key: any;
};

const AddressListItem = ({
  attrs,
  openPopup,
  toggleSelection,
  selected,
}: AddressListItemProps): JSX.Element => {
  return (
    <tr style={{ cursor: 'pointer' }}>
      <td onClick={toggleSelection} style={{ width: '50px' }}>
        <input
          type="checkbox"
          checked={selected}
          onChange={(): void => {
            // Intentionally empty
          }}
        />
      </td>
      <td onClick={openPopup}>{formatAddress(attrs)}</td>
    </tr>
  );
};

export const AddressesView = ({
  transl,
}: {
  transl: (label: string) => string;
}): JSX.Element => {
  const [addresses, setAddresses] = React.useState(
    null as Address[] | null
  );
  const [popupAttrs, setPopupAttrs] = React.useState(
    null as Address | null
  );
  const [addressIDsToRemove, setAddressIDstoRemove] = React.useState(
    [] as string[]
  );

  React.useEffect(() => {
    Gateway.get('/providercompany/addresses').then(setAddresses);
    return (): void => {
      // Intentionally empty
    };
  }, []);

  const reloadAddresses = (): void => {
    Gateway.get('/providercompany/addresses').then(setAddresses);
  };

  const openNewAddressPopup = (): void => {
    setPopupAttrs({
      title: '',
      shortAddress: '',
      postalCode: '',
      notes: '',
      phone: '',
      email: null,
    });
  };

  const deleteSelectedAddresses = async (): Promise<void> => {
    if (
      !window.confirm(
        transl('ConfirmDeleteAddresses')
          .split('#NUM_ADDRESSES#')
          .join(addressIDsToRemove.length.toString())
      )
    ) {
      return;
    }
    setAddresses(null);

    await Promise.all(
      addressIDsToRemove.map((addressID): Promise<void> => {
        return Gateway.del(
          `/providercompany/addresses/id/${addressID}`
        );
      })
    );

    reloadAddresses();
  };

  if (addresses === null) {
    return <LoadingComponent />;
  }
  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ paddingTop: '15px' }}>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={openNewAddressPopup}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;{transl('NewAddress')}
        </button>
        &nbsp; &nbsp; &nbsp;
        <button
          type="button"
          className="btn btn-danger btn-lg"
          onClick={deleteSelectedAddresses}
          disabled={addressIDsToRemove.length === 0}
        >
          {transl('DeleteSelected')}
        </button>
      </div>
      <div style={commonStyles.flexColumnContainer}>
        <FixedTable
          header={
            <tr>
              <th>{transl('DeleteAddress')}</th>
              <th>{transl('Address')}</th>
            </tr>
          }
        >
          {addresses.map((attrs, i) => {
            return (
              <AddressListItem
                key={i}
                attrs={attrs}
                selected={addressIDsToRemove.includes(
                  attrs.addressID || 'notincluded'
                )}
                toggleSelection={(): void => {
                  if (attrs.addressID) {
                    if (
                      addressIDsToRemove.includes(attrs.addressID)
                    ) {
                      setAddressIDstoRemove(
                        addressIDsToRemove.filter(
                          (item) => item !== attrs.addressID
                        )
                      );
                    } else {
                      setAddressIDstoRemove([
                        ...addressIDsToRemove,
                        attrs.addressID,
                      ]);
                    }
                  }
                }}
                openPopup={(): void => setPopupAttrs(attrs)}
              />
            );
          })}
        </FixedTable>
      </div>
      {popupAttrs !== null && (
        <AddressPopup
          transl={transl}
          reloadAddresses={reloadAddresses}
          closePopup={(): void => setPopupAttrs(null)}
          attrs={popupAttrs}
        />
      )}
    </div>
  );
};
