import React from 'react';
import Header from './Header';
import { Notifier } from 'lib/Notifier';
import SpinnerContainer from 'lib/SpinnerContainer';
import { LoadingComponent } from 'lib/LoadingComponent';
import { featureGateInUse, featureGates } from './featureGates';
import colors from 'lib/colors';
import Gateway from './web/gateway';
import { User as PersonalData } from './settings/types';
import { SettingsPage } from './settings/SettingsPage';
import { setCountry } from './settings/helpers';
import { OrderPage } from './order/OrderPage';
import { HistoryPage } from './history/HistoryPage';
import { ScheduledOrdersView } from './scheduledOrders/ScheduledOrdersView';
import { SpecialOrdersPage } from './specialOrders/SpecialOrdersPage';
import {
  listSpecialOrders,
  SpecialOrder,
} from './specialOrders/requests';
import { OrderPage as MultiAddressOrderPage } from './multiAddressOrder/OrderPage';
import { IsAdminContext } from './contexts';

type SupportInfo = {
  merchantSupportPhone: string;
  endCustomerSupportPhone: string;
  endCustomerSupportEmail: string;
  courierSupportPhone: string;
  website: string;
};

const MainView = ({
  transl,
  companyName,
}: {
  transl: (label: string) => string;
  companyName: string | null;
}): JSX.Element => {
  const [chosenView, setChosenView] = React.useState(
    featureGateInUse(featureGates.hideRegularOrdersView)
      ? 'history'
      : 'order'
  );
  const [personalData, setPersonalData] = React.useState(
    null as PersonalData | null
  );
  const [supportInfo, setSupportInfo] = React.useState(
    null as SupportInfo | null
  );
  const [specialOrders, setSpecialOrders] = React.useState(
    [] as SpecialOrder[]
  );

  React.useEffect(() => {
    Gateway.get('/providercompany/users/me/profile').then((d) => {
      setCountry(d.country);
      setPersonalData(d);
    });
    Gateway.get('/support-info').then(setSupportInfo);
    listSpecialOrders().then(setSpecialOrders);
    return (): void => {
      // Intentionally empty
    };
  }, []);
  if (!personalData) return <LoadingComponent />;

  const shouldShowPrices =
    personalData.isAdmin ||
    !featureGateInUse(featureGates.hidePricesFromNonAdmins);

  return (
    <IsAdminContext.Provider value={personalData.isAdmin}>
      <div style={styles.masterContainer}>
        <Notifier>
          <div className="container" style={styles.container}>
            <Header
              transl={transl}
              companyName={companyName}
              chosenView={chosenView}
              setChosenView={setChosenView}
              supportPhone={supportInfo?.merchantSupportPhone}
              supportWebsite={supportInfo?.website}
              personalData={personalData}
              showSpecialOrders={specialOrders.length > 0}
            />
            {chosenView === 'order' &&
              !featureGateInUse(
                featureGates.hideRegularOrdersView
              ) && (
                <OrderPage
                  transl={transl}
                  setChosenView={setChosenView}
                  personalData={personalData}
                />
              )}
            {chosenView === 'specialOrders' && (
              <SpecialOrdersPage
                specialOrders={specialOrders}
                transl={transl}
                setChosenView={setChosenView}
              />
            )}
            {chosenView === 'history' && (
              <HistoryPage transl={transl} />
            )}
            {chosenView === 'settings' && (
              <SettingsPage
                personalData={personalData}
                transl={transl}
              />
            )}
            {chosenView === 'scheduledOrders' &&
              featureGateInUse(
                featureGates.showScheduledOrdersInOrderPanel
              ) &&
              shouldShowPrices && (
                <ScheduledOrdersView transl={transl} />
              )}
            {chosenView === 'multiAddressOrder' &&
              !featureGateInUse(
                featureGates.hideRegularOrdersView
              ) && (
                <MultiAddressOrderPage
                  transl={transl}
                  setChosenView={setChosenView}
                />
              )}
          </div>
          <SpinnerContainer />
        </Notifier>
      </div>
    </IsAdminContext.Provider>
  );
};

const styles = {
  masterContainer: {
    backgroundColor: colors.darkMain,
    flex: '1 1 auto',
  },
  container: {
    backgroundColor: '#eee',
    padding: 0,
    height: '100%',
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
  },
};

export default MainView;
