import * as base32 from 'hi-base32';

export const dynamoKeysToBase32 = (
  hashKey: string,
  rangeKey?: string
): string => {
  const inputArray = [hashKey];
  if (rangeKey) {
    inputArray.push(rangeKey);
  }

  const jsonInput = JSON.stringify(inputArray);
  const base32Input = base32.encode(jsonInput);
  return base32Input.toLowerCase().split('=').join('1');
};

const deg2rad = (deg: number): number => {
  return deg * (Math.PI / 180);
};

export const getDistanceFromLatLonInKm = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371;
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};
