import React from 'react';
import Modal from 'lib/Modal';
import Spinner from 'lib/Spinner';
import Gateway from '../web/gateway';
import { commonStyles } from 'lib/commonStyles';
import { Address } from './types';
import {
  formatAddressForSaving,
  validateAddress,
  addressValidationToStr,
  getCityForPostalcode,
} from './helpers';

export const AddressPopup = ({
  transl,
  closePopup,
  attrs,
  reloadAddresses,
  onSave,
}: {
  transl: (label: string) => string;
  closePopup: () => void;
  attrs: Address;
  reloadAddresses: () => void;
  onSave?: (attrs: Address) => any;
}): JSX.Element => {
  const [fields, setFields] = React.useState({ ...attrs });
  const [loading, setIsLoading] = React.useState(false);

  const isNew = !attrs.addressID;

  const saveAddress = async (): Promise<void> => {
    if (
      !fields.title ||
      !fields.shortAddress ||
      !fields.postalCode ||
      !fields.phone
    )
      return;
    setIsLoading(true);

    const validationResult = await validateAddress(fields, false);
    if (
      !validationResult.geocodingValid ||
      !validationResult.postalcodeValid
    ) {
      setIsLoading(false);
      alert(addressValidationToStr(validationResult, transl));
      return;
    }
    const data = formatAddressForSaving(fields);

    try {
      if (!attrs.addressID) {
        const res = await Gateway.post(`/providercompany/addresses`, {
          data,
        });
        if (onSave) onSave(res);
      } else {
        await Gateway.put(
          `/providercompany/addresses/id/${attrs.addressID}`,
          {
            data,
          }
        );
        if (onSave) onSave(data);
      }
    } catch (err) {
      // Intentionally empty
    }

    reloadAddresses();
    closePopup();
  };

  const deleteAddress = (): void => {
    if (!window.confirm(transl('ConfirmDeleteAddress'))) return;
    setIsLoading(true);
    if (attrs.addressID) {
      Gateway.del(
        `/providercompany/addresses/id/${attrs.addressID}`
      ).then(() => {
        reloadAddresses();
        closePopup();
      });
    }
  };

  const setVal = (key: string, val: string): void => {
    setFields((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const setPostalcode = (rawPostalcode: string): void => {
    const newPostalcode = rawPostalcode.trim();
    setFields((prev) => ({
      ...prev,
      postalCode: newPostalcode,
    }));
    if (newPostalcode.length >= 5) {
      getCityForPostalcode(newPostalcode).then((city) =>
        setFields((prev) => ({
          ...prev,
          city,
        }))
      );
    } else {
      setFields((prev) => ({
        ...prev,
        city: undefined,
      }));
    }
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: window.innerHeight - 100,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };
  return (
    <div>
      <Modal
        transl={transl}
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'yn0'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>{transl(isNew ? 'NewAddress' : 'ModifyAddress')}</h2>
          <p>{transl('AsteriskMarkedFieldsAreMandatory')}</p>
          <div className="form-group">
            <label htmlFor="addresspopup-title">
              {transl('AddressTitle')}*
            </label>
            <input
              type="text"
              value={fields.title}
              className="form-control"
              id="addresspopup-title"
              onChange={(e): void => setVal('title', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="addresspopup-shortAddress">
              {transl('ShortAddress')}*
            </label>
            <input
              type="text"
              value={fields.shortAddress}
              className="form-control"
              id="addresspopup-shortAddress"
              onChange={(e): void =>
                setVal('shortAddress', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="addresspopup-postalCode">
              {transl('PostalCode')}*
            </label>
            <input
              type="text"
              value={fields.postalCode}
              className="form-control"
              id="addresspopup-postalCode"
              onChange={(e): void => setPostalcode(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="addresspopup-postalCode">
              {transl('City')}: {fields.city}
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="addresspopup-phone">
              {transl('PhoneNumber')}*
            </label>
            <input
              type="text"
              value={fields.phone}
              className="form-control"
              id="addresspopup-phone"
              onChange={(e): void => setVal('phone', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="addresspopup-email">
              {transl('Email')}
            </label>
            <input
              type="text"
              value={fields.email || ''}
              className="form-control"
              id="addresspopup-email"
              onChange={(e): void => setVal('email', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="addresspopup-notes">
              {transl('ArrivalInstructions')}
            </label>
            <textarea
              value={fields.notes || ''}
              className="form-control"
              id="addresspopup-notes"
              onChange={(e): void => setVal('notes', e.target.value)}
            />
          </div>
          {!isNew && (
            <div className="form-group">
              <button
                type="button"
                className="btn btn-danger btn-lg btn-block"
                onClick={deleteAddress}
              >
                <i className="fa fa-trash-o"></i>
                &nbsp;&nbsp;{transl('DeleteAddress')}
              </button>
            </div>
          )}
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveAddress}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp;{transl('Save')}
            </button>
          </div>
        </div>
      </Modal>
      <Spinner visible={loading} />
    </div>
  );
};
