import React from 'react';
import FixedTable from 'lib/FixedTable';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import {
  SpecialOrder,
  listSpecialOrders,
} from '../specialOrders/requests';

const formatSum = (
  cents: number,
  transl: (label: string) => string
): string => {
  return (cents / 100)
    .toFixed(2)
    .split('.')
    .join(transl('DecimalSeparator'));
};

type ScheduledOrderListItemProps = {
  attrs: SpecialOrder;
  transl: (label: string) => string;
  key: any;
};

const ScheduledOrderListItem = ({
  attrs,
  transl,
  key,
}: ScheduledOrderListItemProps): JSX.Element => (
  <tr key={key}>
    <td>{attrs.name}</td>
    <td>{formatSum(attrs.merchantPrice, transl)}</td>
    <td>{formatSum(attrs.merchantSundayExtra, transl)}</td>
  </tr>
);

export const ScheduledOrdersView = ({
  transl,
}: {
  transl: (label: string) => string;
}): JSX.Element => {
  const [orders, setOrders] = React.useState(
    null as SpecialOrder[] | null
  );

  React.useEffect(() => {
    listSpecialOrders().then(setOrders);
    return (): void => {
      // Intentionally empty
    };
  }, []);

  if (orders === null) {
    return <LoadingComponent />;
  }
  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={commonStyles.defaultContainer}>
        <FixedTable
          header={
            <tr>
              <th>{transl('RouteName')}</th>
              <th>{transl('PriceWithoutVat')}</th>
              <th>{transl('SundayExtra')}</th>
            </tr>
          }
        >
          {orders.map((attrs, i) => {
            return (
              <ScheduledOrderListItem
                key={i}
                attrs={attrs}
                transl={transl}
              />
            );
          })}
        </FixedTable>
      </div>
    </div>
  );
};
