import fi from './fi';
import en from './en';

const translations = {
  fi,
  en,
};

export const gettext = (lang: string) => (label: string): string => {
  if (translations[lang][label]) return translations[lang][label];
  throw new Error(`Translation for ${label} not found`);
};
