import moment from 'lib/moment';

import React from 'react';
import colors from 'lib/colors';
import DatePicker from './DatePicker';

import { featureGateInUse, featureGates } from '../featureGates';

const EARLIEST_HOUR = 8;
const LATEST_HOUR = 21;

const isToday = (date: moment.Moment): boolean => {
  return date.isSame(new Date(), 'day');
};

const getEarliestHourForDate = (date: moment.Moment): number => {
  let res = EARLIEST_HOUR;
  if (isToday(date)) {
    const nextHour = moment().hour() + 1;
    if (nextHour > res) res = nextHour;
  }
  return res;
};

const getHoursForDate = (date: moment.Moment): number[] => {
  const firstHour = getEarliestHourForDate(date);
  const res: number[] = [];
  for (let hour = firstHour; hour < LATEST_HOUR; hour++) {
    res.push(hour);
  }
  return res;
};

const get15MinPickupTimes = (date: moment.Moment): string[] => {
  const firstHour = getEarliestHourForDate(date);
  const res: string[] = [];
  for (let hour = firstHour; hour < LATEST_HOUR; hour++) {
    res.push(`${hour}:00`);
    res.push(`${hour}:15`);
    res.push(`${hour}:30`);
    res.push(`${hour}:45`);
  }
  return res;
};

type Props = {
  pickupTime: string;
  setPickupTime: (time: string) => void;
  transl: (label: string) => string;
};

export const TimeSlotSelector = ({
  pickupTime,
  setPickupTime,
  transl,
}: Props): JSX.Element => {
  const today = moment()
    .set({
      hour: getEarliestHourForDate(moment()),
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .toISOString();

  const dates = [
    {
      label: `${transl('Today')} ${moment(today).format('L')}`,
      time: today,
    }
  ];

  for (let i = 0; i < 6; i++) {
    const lastDateTimestamp = dates[dates.length - 1].time;
    const nextDate = moment(lastDateTimestamp)
    .add(1, 'days')
    .set({
      hour: getEarliestHourForDate(moment(lastDateTimestamp).add(1, 'days')),
    })
    .toISOString();
    dates.push({
      label: `${moment(nextDate).format('dd')} ${moment(nextDate).format('L')}`,
      time: nextDate,
    });
  }

  React.useEffect(() => {
    if (isToday(moment(pickupTime))) {
      const hour = moment(pickupTime).hour();
      if (hour >= LATEST_HOUR) {
        setPickupTime(
          moment(pickupTime)
            .add(1, 'days')
            .set({
              hour: getEarliestHourForDate(moment().add(1, 'days')),
            })
            .toISOString()
        );
      } else if (hour < EARLIEST_HOUR) {
        setPickupTime(
          moment(pickupTime)
            .set({
              hour: getEarliestHourForDate(moment()),
            })
            .toISOString()
        );
      }
    }
    return (): void => {
      // Intentionally empty
    };
  }, [pickupTime, setPickupTime]);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-xs-6">
          <div className="form-group">
            <label>{transl('Date')}</label>
            {featureGateInUse(
              featureGates.advancedTimeSelectionOptions
            ) ? (
              <DatePicker
                minDate={moment().toDate()}
                selected={moment(pickupTime)
                  .set({
                    hour: getEarliestHourForDate(moment(pickupTime)),
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                  })
                  .toDate()}
                className="form-control"
                onChange={(val): void => {
                  if (val) {
                    setPickupTime(
                      moment(val)
                        .set({
                          hour: getEarliestHourForDate(moment(val)),
                          minute: 0,
                          second: 0,
                          millisecond: 0,
                        })
                        .toISOString()
                    );
                  }
                }}
                dateFormat={'dd.MM.yyyy'}
              />
            ) : (
              <select
                className="form-control"
                value={moment(pickupTime)
                  .set({
                    hour: getEarliestHourForDate(moment(pickupTime)),
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                  })
                  .toISOString()}
                onChange={(e): void => setPickupTime(e.target.value)}
              >
                {dates.map((date, i) => (
                  <option key={i} value={date.time}>
                    {date.label}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
        <div className="col-xs-6">
          <div className="form-group">
            <label>{transl('Time')}</label>
            {featureGateInUse(
              featureGates.advancedTimeSelectionOptions
            ) ? (
              <select
                className="form-control"
                value={`${moment(pickupTime)
                  .hour()
                  .toString()}:${moment(pickupTime)
                  .minute()
                  .toString()
                  .padStart(2, '0')}`}
                onChange={(e): void => {
                  const val = e.target.value.split(':');
                  const newTime = moment(pickupTime).set({
                    hour: parseInt(val[0], 10),
                    minute: parseInt(val[1], 10),
                  });
                  setPickupTime(newTime.toISOString());
                }}
              >
                {get15MinPickupTimes(moment(pickupTime)).map(
                  (time, i) => (
                    <option key={i} value={time}>
                      {time}
                    </option>
                  )
                )}
              </select>
            ) : (
              <select
                className="form-control"
                value={moment(pickupTime).hour().toString()}
                onChange={(e): void => {
                  const newTime = moment(pickupTime).set({
                    hour: parseInt(e.target.value, 10),
                  });
                  setPickupTime(newTime.toISOString());
                }}
              >
                {getHoursForDate(moment(pickupTime)).map(
                  (hour, i) => (
                    <option key={i} value={hour.toString()}>
                      {`${hour}:00`} - {`${hour + 1}:00`}
                    </option>
                  )
                )}
              </select>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <p style={{ color: colors.warning }}>
            {transl('TimedOrderWarning')}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
