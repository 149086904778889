import { Address } from './types';
import Gateway from '../web/gateway';

let country = 'FI';

export const setCountry = (c: string) => {
  country = c;
};

type AddressValidationResult = {
  postalcodeValid: boolean;
  geocodingValid: boolean;
};

export const formatAddress = (address: Address): string => {
  return `${address.title} (${address.shortAddress}, ${
    address.postalCode
  } ${address.city || ''})`;
};

export const getCityForPostalcode = async (
  postalcode: string
): Promise<string> => {
  const res = await Gateway.get(
    `/providercompany/postalcodelookup?postalcode=${postalcode
      .split(' ')
      .join('')}`
  );
  return res.city;
};

const validatePostalcode = async (
  postalcode: string,
  allowedSendLocationsOnly: boolean
): Promise<boolean> => {
  try {
    const res = await Gateway.get(
      `/providercompany/postalcodelookup?postalcode=${postalcode
        .split(' ')
        .join('')}&allowedsendlocationsonly=${
        allowedSendLocationsOnly ? 'true' : 'false'
      }`
    );
    return !!res.city;
  } catch (err) {
    return false;
  }
};

const validateAddressGeocoding = async (
  streetAddress: string,
  postalCode: string
): Promise<boolean> => {
  try {
    const res = await Gateway.post('/providercompany/addresslookup', {
      data: {
        streetAddress,
        postalCode,
      },
      strictGeocoding: true,
    });
    return !!res.lat && !!res.long;
  } catch (err) {
    return false;
  }
};

export const validateAddress = async (
  address: Address,
  allowedSendLocationsOnly: boolean
): Promise<AddressValidationResult> => {
  const [postalcodeValid, geocodingValid] = await Promise.all([
    validatePostalcode(address.postalCode, allowedSendLocationsOnly),
    validateAddressGeocoding(
      address.shortAddress,
      address.postalCode
    ),
  ]);
  return { postalcodeValid, geocodingValid };
};

export const formatFinnishOrSwedishPhoneNumber = (
  input: string
): string => {
  const countryCodes = { FI: '+358', SE: '+46' };
  const code = countryCodes[country] ?? '+358';
  const phone = input.split(' ').join('').split('-').join('');
  if (phone.startsWith('00358')) return '+358' + phone.substring(5);
  if (phone.startsWith('0046')) return '+46' + phone.substring(5);
  if (phone.startsWith('0')) return code + phone.substring(1);
  return phone;
};

export const formatAddressForSaving = (address: Address): Address => {
  const res = {
    title: address.title,
    shortAddress: address.shortAddress,
    postalCode: address.postalCode,
    notes: address.notes || '-',
    email: address.email || null,
    phone: formatFinnishOrSwedishPhoneNumber(address.phone),
  };
  return res;
};

export const addressValidationToStr = (
  validation: AddressValidationResult,
  transl: (label: string) => string
): string => {
  let res = transl('AddressFailureMsg');
  if (!validation.geocodingValid) {
    res += '- ' + transl('AddressFailureGeocoding') + '\n';
  }
  if (!validation.postalcodeValid) {
    res += '- ' + transl('AddressFailurePostalcode') + '\n';
  }
  return res;
};
