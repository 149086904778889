import React from 'react';
import colors from 'lib/colors';
import {
  getLogoUrl,
  featureGateInUse,
  featureGates,
} from './featureGates';
import { IS_TEST_SERVER } from './web/constants';
import { User } from './settings/types';

const Header = ({
  transl,
  companyName,
  chosenView,
  setChosenView,
  personalData,
  showSpecialOrders,
}: {
  transl: (label: string) => string;
  companyName: string | null;
  chosenView: string;
  setChosenView: (view: string) => void;
  supportPhone?: string;
  supportWebsite?: string;
  personalData: User;
  showSpecialOrders: boolean;
}): JSX.Element => (
  <div className="container-fluid" style={styles().header}>
    <div className="row">
      <h5
        style={styles().headerText}
        dangerouslySetInnerHTML={{
          __html: transl('MyxlineContactDetails'),
        }}
      ></h5>
      {!featureGateInUse(featureGates.synlabCustomizations) && (
        <h5 style={styles().headerText}>
          {companyName}{' '}
          {IS_TEST_SERVER && (
            <span>(Testiympäristö / Test Environment)</span>
          )}
        </h5>
      )}
      <img alt="logo" src={getLogoUrl()} style={styles().logo} />
    </div>
    <div className="row">
      <div style={styles().headerBtnContainer}>
        {!featureGateInUse(featureGates.hideRegularOrdersView) && (
          <div
            className={`button-header${
              featureGateInUse(featureGates.synlabCustomizations)
                ? '-synlab'
                : ''
            } ${chosenView === 'order' ? 'active' : ''}`}
            onClick={(): void => setChosenView('order')}
          >
            {transl('TransportOrder')}
          </div>
        )}
        <div
          className={`button-header${
            featureGateInUse(featureGates.synlabCustomizations)
              ? '-synlab'
              : ''
          } ${chosenView === 'history' ? 'active' : ''}`}
          onClick={(): void => setChosenView('history')}
        >
          {transl('TransportOrderHistory')}
        </div>
        {showSpecialOrders && (
          <div
            className={`button-header${
              featureGateInUse(featureGates.synlabCustomizations)
                ? '-synlab'
                : ''
            } ${chosenView === 'specialOrders' ? 'active' : ''}`}
            onClick={(): void => setChosenView('specialOrders')}
          >
            {transl('SpecialOrders')}
          </div>
        )}
        {!featureGateInUse(featureGates.synlabCustomizations) &&
          !featureGateInUse(featureGates.hideRegularOrdersView) && (
            <div
              className={`button-header ${
                chosenView === 'multiAddressOrder' ? 'active' : ''
              }`}
              onClick={(): void => setChosenView('multiAddressOrder')}
            >
              {transl('MultiAddressOrder')}
            </div>
          )}
        {featureGateInUse(
          featureGates.showScheduledOrdersInOrderPanel
        ) &&
          personalData.isAdmin &&
          window.innerWidth > 800 && (
            <div
              className={`button-header${
                featureGateInUse(featureGates.synlabCustomizations)
                  ? '-synlab'
                  : ''
              } ${chosenView === 'scheduledOrders' ? 'active' : ''}`}
              onClick={(): void => setChosenView('scheduledOrders')}
            >
              {transl('ScheduledOrders')}
            </div>
          )}
        <div
          className={`button-header${
            featureGateInUse(featureGates.synlabCustomizations)
              ? '-synlab'
              : ''
          } ${chosenView === 'settings' ? 'active' : ''}`}
          onClick={(): void => setChosenView('settings')}
        >
          {transl('Settings')}
        </div>
      </div>
    </div>
  </div>
);

const defaultStyles = {
  logo: {
    height: '60px',
    width: '60px',
    position: 'absolute' as 'absolute',
    right: 20,
    top: 10,
    display: document.body.clientWidth < 800 ? 'none' : 'block',
  },
  header: {
    backgroundColor: colors.main,
    position: 'relative' as 'relative',
    margin: 0,
  },
  headerText: {
    color: 'white',
    flex: '1 1 auto',
    margin: '0px',
    padding: '15px',
    marginRight: '100px',
  },
  headerBtnContainer: {
    width: '70%',
    maxWidth: '800px',
    display: 'flex',
    flexDirection: 'row' as 'row',
    paddingLeft: '15px',
  },
};

const synlabStyles = {
  logo: {
    height: '58px',
    width: '250px',
    position: 'absolute' as 'absolute',
    right: 20,
    top: 10,
    display: document.body.clientWidth < 800 ? 'none' : 'block',
  },
  header: {
    backgroundColor: '#fff',
    position: 'relative' as 'relative',
    margin: 0,
  },
  headerText: {
    color: '#003765',
    flex: '1 1 auto',
    margin: '0px',
    padding: '15px',
  },
  headerBtnContainer: {
    width: '70%',
    maxWidth: '800px',
    display: 'flex',
    flexDirection: 'row' as 'row',
    paddingLeft: '15px',
  },
};

const styles = (): any =>
  featureGateInUse(featureGates.synlabCustomizations)
    ? synlabStyles
    : defaultStyles;

export default Header;
