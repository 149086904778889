const Colors = {
  darkMain: '#0e2760',
  main: '#133791',
  lightMain: '#4f80ff',
  lightMain2: '#e4fff9',
  primary: '#337ab7',
  success: '#449d44',
  info: '#31b0d5',
  warning: '#ec971f',
  danger: '#c9302c',
  darkGrey: '#333',
  middleGrey: '#999',
  lightGrey: '#ccc',
};
export default Colors;
