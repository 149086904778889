import React from 'react';
import Modal from 'lib/Modal';
import Spinner from 'lib/Spinner';
import { TimeSlotSelector } from 'lib/TimeSlotSelector';
import Gateway from '../web/gateway';
import { commonStyles } from 'lib/commonStyles';
import { TransportOrder } from './types';

export const TimeslotPopup = ({
  transl,
  closePopup,
  attrs,
  reloadOrders,
}: {
  closePopup: () => void;
  attrs: TransportOrder;
  transl: (label: string) => string;
  reloadOrders: () => void;
}): JSX.Element => {
  const [loading, setIsLoading] = React.useState(false);
  const [pickupTime, setPickupTime] = React.useState(
    attrs.earliestPickupTime
  );

  const cancelOrder = (): void => {
    if (!window.confirm(transl('ConfirmDeleteOrder'))) return;
    setIsLoading(true);
    Gateway.del(
      `/providercompany/orders/id/${attrs.transportOrderId}/cancel`
    )
      .then(() => {
        reloadOrders();
        closePopup();
      })
      .catch(() => {
        setIsLoading(false);
        alert(transl('ErrorRemovingOrder'));
      });
  };

  const modifyPickupTime = (): void => {
    setIsLoading(true);
    Gateway.post(
      `/providercompany/orders/id/${attrs.transportOrderId}/modify`,
      {
        data: {
          earliestPickupTime: pickupTime,
        },
      }
    )
      .then(() => {
        reloadOrders();
        closePopup();
      })
      .catch(() => {
        setIsLoading(false);
        alert(transl('ErrorModifyingOrder'));
      });
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      maxHeight: window.innerHeight - 100,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };
  return (
    <div>
      <Modal
        transl={transl}
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'yn0'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>{transl('ModifyOrCancelOrder')}</h2>
          <TimeSlotSelector
            pickupTime={pickupTime}
            setPickupTime={setPickupTime}
            transl={transl}
          />
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexRowContainer}>
            <div
              style={{
                ...commonStyles.flexColumnContainer,
                paddingRight: '15px',
              }}
            >
              <button
                type="button"
                className="btn btn-danger btn-lg btn-block"
                onClick={cancelOrder}
              >
                <i className="fa fa-trash"></i>
                &nbsp;&nbsp;{transl('CancelOrder')}
              </button>
            </div>
            <div style={commonStyles.flexColumnContainer}>
              <button
                type="button"
                className="btn btn-primary btn-lg btn-block"
                onClick={modifyPickupTime}
              >
                <i className="fa fa-floppy-o"></i>
                &nbsp;&nbsp;{transl('Save')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Spinner visible={loading} />
    </div>
  );
};
