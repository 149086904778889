/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import uuidv4 from 'uuid/v4';
import './FixedTable.css';
import React from 'react';

// @ts-ignore
window.$ = require('jquery');
require('floatthead');

const childrenVisibleIncrement = 50;

type Props = {
  header: JSX.Element;
  children: JSX.Element | JSX.Element[];
  loadMore?: () => any;
};

const FixedTable = ({
  header,
  children,
  loadMore,
}: Props): JSX.Element => {
  const [childrenVisible, setChildrenVisible] = React.useState(50);
  const tableId = React.useState('floatingTable-' + uuidv4())[0];

  let childrenToRender = React.Children.toArray(children);
  childrenToRender = childrenToRender.slice(0, childrenVisible);

  const handleScroll = React.useCallback((): void => {
    const nofChildren = React.Children.toArray(children).length;
    const nofVisibleChildren = childrenVisible;
    const maxScroll =
      // @ts-ignore
      $('#' + tableId).height() -
      // @ts-ignore
      $('#' + tableId)
        .closest('.scroll-wrapper')
        .height();
    const currentScroll = $('#' + tableId)
      .closest('.scroll-wrapper')
      .scrollTop();

    if (maxScroll <= 10) {
      setChildrenVisible(nofChildren);
    }
    if ((currentScroll || 0) * 1.2 >= maxScroll) {
      if (nofVisibleChildren < nofChildren) {
        setChildrenVisible(
          nofVisibleChildren + childrenVisibleIncrement
        );
      }
      if (loadMore) loadMore();
    }
  }, [children, childrenVisible, loadMore, tableId]);

  React.useEffect(() => {
    // @ts-ignore
    $('#' + tableId).floatThead({
      scrollContainer: ($table: any) => {
        return $table.closest('.scroll-wrapper');
      },
      zIndex: 50,
    });
    return (): void => {
      try {
        // @ts-ignore
        $('#' + tableId).floatThead('destroy');
      } finally {
        // always runs
      }
    };
  }, [tableId]);

  return (
    <div className="scroll-wrapper-wrapper">
      <div className="scroll-wrapper" onScroll={handleScroll}>
        <table
          id={tableId}
          className="table table-striped table-hover"
        >
          <thead>{header}</thead>
          <tbody>{childrenToRender}</tbody>
        </table>
      </div>
    </div>
  );
};
export default FixedTable;
