import React from 'react';

const isMobile = (): boolean => {
  if (document.body.clientWidth > 1200) return false;
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  }
  return false;
};

type Props = {
  children: any;
};

export const MobileKeyboardHidingWrapper = ({
  children,
}: Props): JSX.Element => {
  const [
    keyboardVisibleInMobile,
    setKeyboardVisibleInMobile,
  ] = React.useState(false);

  React.useEffect(() => {
    const keyboardPoppedUp = (e: any): void => {
      if (
        isMobile() &&
        ['input', 'textarea'].includes(e.target.tagName.toLowerCase())
      ) {
        setKeyboardVisibleInMobile(true);
      }
    };
    const keyboardDisappeared = (): void => {
      setKeyboardVisibleInMobile(false);
    };
    document.addEventListener('focusin', keyboardPoppedUp);
    document.addEventListener('focusout', keyboardDisappeared);
    return (): void => {
      document.removeEventListener('focusin', keyboardPoppedUp);
      document.removeEventListener('focusout', keyboardDisappeared);
    };
  }, []);
  return (
    <div
      style={{
        display: keyboardVisibleInMobile ? 'none' : 'block',
      }}
    >
      {children}
    </div>
  );
};
