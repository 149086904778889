import moment from 'lib/moment';
import { TimeSlotSelector } from 'lib/TimeSlotSelector';

import React from 'react';

type Props = {
  pickupTime: string | null;
  setPickupTime: (time: string | null) => void;
  transl: (label: string) => string;
};

export const PickupTimeSelector = ({
  pickupTime,
  setPickupTime,
  transl,
}: Props): JSX.Element => {
  return (
    <div>
      <div className="form-group">
        <label>{transl('OrderPickupTime')}</label>
        <select
          className="form-control"
          value={!!pickupTime ? 'later' : 'now'}
          onChange={(e): void => {
            const value = e.target.value;
            if (value === 'now') {
              setPickupTime(null);
            } else {
              setPickupTime(
                moment()
                  .set({
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                  })
                  .add(1, 'hours')
                  .toISOString()
              );
            }
          }}
        >
          <option value="now">{transl('Immediately')}</option>
          <option value="later">{transl('SetLaterTime')}</option>
        </select>
      </div>
      {!!pickupTime && (
        <TimeSlotSelector
          pickupTime={pickupTime}
          setPickupTime={setPickupTime}
          transl={transl}
        />
      )}
    </div>
  );
};
