import React from 'react';
import { commonStyles } from './commonStyles';

type Props = {
  children: any;
};

export const ScrollableFlexContainer = ({
  children,
}: Props): JSX.Element => {
  return (
    <div
      style={{
        ...commonStyles.flexColumnContainer,
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          overflow: 'auto',
          padding: '15px',
        }}
      >
        {children}
      </div>
    </div>
  );
};
