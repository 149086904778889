import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import Spinner from 'lib/Spinner';
import colors from 'lib/colors';
import moment from 'moment';
import { Accordion } from 'lib/Accordion';
import { featureGateInUse, featureGates } from '../featureGates';
import { PickupTimeSelector } from '../order/PickupTimeSelector';
import Select from 'react-virtualized-select';
import { ScrollableFlexContainer } from 'lib/ScrollableFlexContainer';
import { validateExternalID } from '../order/helpers';
import { debounce } from 'lodash';
import { MobileKeyboardHidingWrapper } from 'lib/MobileKeyboardHidingWrapper';
import { IsAdminContext } from '../contexts';

import {
  SpecialOrder,
  createOrderFromSpecialOrder,
} from './requests';

const range = (start: number, end: number): number[] => {
  const res: number[] = [];
  for (let i = start; i < end; i++) {
    res.push(i);
  }
  return res;
};
const timestampIsOnSunday = (timestamp: string): boolean => {
  const date = timestamp.substring(0, 10);
  return moment(date).isoWeekday() === 7;
};

const OrderInfoComponent = ({
  transl,
  order,
  noPaddingLeft,
}: {
  transl: (label: string) => string;
  order: SpecialOrder;
  noPaddingLeft: boolean;
}): JSX.Element => {
  return (
    <div className="row">
      <div
        className={`col-sm-6 ${
          noPaddingLeft ? 'no-padding-left' : ''
        }`}
        style={{ paddingBottom: '15px' }}
      >
        <b>{transl('PickupAddress')}:</b>
        <br />
        {order.orderCreateFields.startAddressTitle}
        <br />
        {order.orderCreateFields.startAddress},{' '}
        {order.orderCreateFields.startPostalCode}
        <br />
        {order.orderCreateFields.startAddressPhone}
        <br />
        {order.orderCreateFields.startAddressNotes}
      </div>
      <div
        className={`col-sm-6 ${
          noPaddingLeft ? 'no-padding-left' : ''
        }`}
        style={{ paddingBottom: '15px' }}
      >
        <b>{transl('Recipient')}:</b>
        <br />
        {order.orderCreateFields.recipientName}
        <br />
        {order.orderCreateFields.recipientAddress},{' '}
        {order.orderCreateFields.recipientPostalCode}
        <br />
        {order.orderCreateFields.recipientPhoneNo}
        <br />
        {order.orderCreateFields.recipientEmail}
        <br />
        {order.orderCreateFields.recipientAddressNotes}
      </div>
    </div>
  );
};

const HeaderExpansionItem = ({
  transl,
  externalID,
  pickupTime,
  order,
}: {
  transl: (label: string) => string;
  externalID: string | null;
  pickupTime: string | null;
  order: SpecialOrder;
}): JSX.Element => {
  return (
    <div
      className="container-fluid"
      style={styles.expansionContainer}
    >
      <OrderInfoComponent
        order={order}
        transl={transl}
        noPaddingLeft={true}
      />
      <div className="row">
        <div className="col-sm-12 no-padding-left">
          {externalID && (
            <span>
              {transl('OrderNumber')}: {externalID}
            </span>
          )}
          {pickupTime && <br />}
          {pickupTime &&
            `${transl('EarliestPickupTime')} ${moment(
              pickupTime
            ).format('L')} ${moment(pickupTime).format('HH:mm')}`}
        </div>
      </div>
    </div>
  );
};

const getHeaderExpansionItem = (
  transl: (label: string) => string,
  externalID: string | null,
  pickupTime: string | null,
  order: SpecialOrder
): JSX.Element => (
  <HeaderExpansionItem
    transl={transl}
    externalID={externalID}
    pickupTime={pickupTime}
    order={order}
  />
);

export const SpecialOrdersPage = ({
  transl,
  specialOrders,
  setChosenView,
}: {
  transl: (label: string) => string;
  setChosenView: (view: string) => void;
  specialOrders: SpecialOrder[];
}): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const [stage, setStage] = React.useState<'modify' | 'check'>(
    'modify'
  );
  const [currentPrice, setCurrentPrice] = React.useState<
    number | null
  >(null);
  const [specialOrderID, setSpecialOrderID] = React.useState<
    string | null
  >(null);
  const [earliestPickupTime, setEarliestPickupTime] = React.useState<
    string | null
  >(null);
  const [numPackages, setNumPackages] = React.useState<number>(1);
  const [externalID, setExternalID] = React.useState<string | null>(
    null
  );
  const [externalIDValid, setExternalIDValid] = React.useState(
    null as null | boolean
  );

  const performValidateExternalID = React.useCallback(
    debounce((val: string): void => {
      setExternalIDValid(null);
      validateExternalID(val).then(setExternalIDValid);
    }, 500),
    [setExternalIDValid]
  );

  React.useEffect(() => {
    setExternalIDValid(null);
    if (externalID) performValidateExternalID(externalID);
    return (): void => {
      // Intentionally empty
    };
  }, [performValidateExternalID, externalID]);

  const chosenOrder = specialOrders.find(
    (item) => item.id === specialOrderID
  );

  const isAdmin = React.useContext(IsAdminContext);
  const shouldShowPrices =
    isAdmin ||
    !featureGateInUse(featureGates.hidePricesFromNonAdmins);

  const calculateAndUpdatePrice = (): void => {
    if (!chosenOrder || !shouldShowPrices) return;
    let price = chosenOrder.merchantPrice;
    if (
      timestampIsOnSunday(
        earliestPickupTime || moment().toISOString()
      )
    ) {
      price += chosenOrder.merchantSundayExtra;
    }
    setCurrentPrice(price || null);
  };

  const next = async (): Promise<void> => {
    if (!specialOrderID) return;
    if (stage === 'modify') {
      setLoading(true);
      const externalIDValid = externalID
        ? await validateExternalID(externalID)
        : true;
      if (externalIDValid) {
        setStage('check');
        calculateAndUpdatePrice();
      }
      setLoading(false);
    }
  };

  const prev = (): void => {
    if (!specialOrderID) return;
    if (stage === 'check') {
      setStage('modify');
    }
  };

  const sendOrder = async (): Promise<void> => {
    if (!specialOrderID) return;
    try {
      setLoading(true);
      await createOrderFromSpecialOrder(
        specialOrderID,
        earliestPickupTime,
        externalID,
        numPackages
      );
    } catch (err) {
      window.alert(
        transl('OrderSendingFailed') + transl('UnknownReason')
      );
    }
    setLoading(false);
    setChosenView('history');
  };

  const isExpanded = stage === 'modify';

  const headerExpansionItem =
    !isExpanded && !!chosenOrder
      ? getHeaderExpansionItem(
          transl,
          externalID,
          earliestPickupTime,
          chosenOrder
        )
      : null;

  const titleTextAlign =
    !!chosenOrder && !isExpanded ? 'left' : 'center';

  const dropdownOptions = specialOrders.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const selectedDropdownOption: any = chosenOrder
    ? {
        label: chosenOrder.name,
        value: chosenOrder.id,
      }
    : null;

  return (
    <div style={commonStyles.flexColumnContainer}>
      <ScrollableFlexContainer>
        <Accordion
          titleTextAlign={titleTextAlign}
          isExpanded={isExpanded}
          title={transl('TransportOrder')}
          imgUrl={'/img/info.png'}
          headerExpansionItem={headerExpansionItem}
          accordionExtraClassName={
            featureGateInUse(featureGates.synlabCustomizations)
              ? 'accordion-synlab'
              : ''
          }
        >
          <div
            className="container-fluid"
            style={{ padding: '15px' }}
          >
            <div className="row">
              <div className="col-xs-12">
                <div className="form-group">
                  <label>{transl('ChooseSpecialOrder')}</label>
                  <Select
                    placeholder={transl('ChooseSpecialOrder')}
                    options={dropdownOptions}
                    onChange={(newVal: any): void => {
                      setSpecialOrderID(newVal?.value ?? null);
                    }}
                    value={selectedDropdownOption}
                  />
                </div>
              </div>
            </div>
            {chosenOrder && (
              <OrderInfoComponent
                transl={transl}
                order={chosenOrder}
                noPaddingLeft={false}
              />
            )}
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="orderpanel-externalid">
                    {transl('OrderNumber')}
                  </label>
                  <input
                    type="text"
                    value={externalID || ''}
                    className="form-control"
                    id="orderpanel-externalid"
                    onChange={(e): void =>
                      setExternalID(e.target.value)
                    }
                  />
                  {externalIDValid === true && (
                    <div style={styles.successMsg}>
                      <i className="fa fa-check"></i>
                      {transl('OrderNumberValidMsg')}
                    </div>
                  )}
                  {externalIDValid === false && (
                    <div style={styles.errorMsg}>
                      <i className="fa fa-times"></i>
                      {transl('OrderNumberInvalidMsg')}
                    </div>
                  )}
                  {externalIDValid === null && !!externalID && (
                    <div>
                      <i className="fa fa-spinner fa-spin"></i>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>{transl('NumberOfPackages')}</label>
                  <select
                    className="form-control"
                    value={numPackages.toString()}
                    onChange={(e): void => {
                      setNumPackages(parseInt(e.target.value, 10));
                    }}
                  >
                    {range(1, 31).map((item) => {
                      return (
                        <option
                          key={item.toString()}
                          value={item.toString()}
                        >
                          {item.toString()}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <PickupTimeSelector
                  pickupTime={earliestPickupTime}
                  setPickupTime={setEarliestPickupTime}
                  transl={transl}
                />
              </div>
            </div>
          </div>
        </Accordion>
      </ScrollableFlexContainer>
      <MobileKeyboardHidingWrapper>
        <div className="container-fluid" style={styles.footer}>
          <div className="row">
            <div className="col-sm-5">
              {currentPrice !== null && shouldShowPrices && (
                <h1 className="text text-center normaltxt-on-mobile">
                  {(currentPrice / 100.0)
                    .toFixed(2)
                    .split('.')
                    .join(transl('DecimalSeparator'))}
                  &nbsp;€
                </h1>
              )}
            </div>
            <div className="col-sm-7">
              <div style={commonStyles.flexRowContainer}>
                <div style={commonStyles.defaultContainer}>
                  {stage === 'check' && (
                    <button
                      className="button-standard"
                      onClick={prev}
                      disabled={!specialOrderID}
                    >
                      {transl('Previous')}&nbsp;&nbsp;
                      <i
                        className="fa fa-undo"
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                </div>
                <div style={commonStyles.defaultContainer}>
                  {stage === 'modify' && (
                    <button
                      className="button-standard"
                      onClick={next}
                      disabled={!specialOrderID}
                    >
                      {transl('Next')}&nbsp;&nbsp;
                      <i
                        className="fa fa-check"
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                  {stage === 'check' && (
                    <button
                      className="button-standard"
                      onClick={sendOrder}
                      disabled={!specialOrderID}
                    >
                      {transl('SendOrder')}&nbsp;&nbsp;
                      <i
                        className="fa fa-check"
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MobileKeyboardHidingWrapper>
      <Spinner visible={loading} />
    </div>
  );
};

const styles = {
  footer: {
    backgroundColor: colors.main,
    position: 'relative' as 'relative',
    margin: 0,
    padding: '15px',
  },
  expansionContainer: {
    paddingLeft: '75px',
    paddingRight: '75px',
    margin: 0,
  },
  errorMsg: {
    color: colors.danger,
  },
  successMsg: {
    color: colors.success,
  },
};
