import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import { LoadingComponent } from 'lib/LoadingComponent';
import { ScrollableFlexContainer } from 'lib/ScrollableFlexContainer';
import { MobileKeyboardHidingWrapper } from 'lib/MobileKeyboardHidingWrapper';
import Spinner from 'lib/Spinner';
import colors from 'lib/colors';
import moment from 'moment';

import {
  validateExternalID,
  currencyToSymbol,
} from '../order/helpers';
import { debounce } from 'lodash';
import { StartAddressPanel } from './StartAddressPanel';
import { EndAddressesPanel } from './EndAddressesPanel';
import { OrderPanel } from './OrderPanel';

import { Address } from '../settings/types';
import {
  AddressesByID,
  getAddresses,
  getPrice,
  createOrder,
} from './requests';
import { IsAdminContext } from '../contexts';
import { featureGateInUse, featureGates } from '../featureGates';

const generateUniqueExternalID = (): string => {
  return moment().format('YYYYMMDD-HHmmss-SSS');
};

export const OrderPage = ({
  transl,
  setChosenView,
}: {
  transl: (label: string) => string;
  setChosenView: (view: string) => void;
}): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const [stepNo, setStepNo] = React.useState(1);

  const [addresses, setAddresses] = React.useState(
    null as Address[] | null
  );
  const [addressesByID, setAddressesByID] = React.useState(
    {} as AddressesByID
  );
  const [currentPrice, setCurrentPrice] = React.useState(
    null as number | null
  );
  const [currency, setCurrency] = React.useState('EUR');

  const [startAddressID, setStartAddressID] = React.useState(
    null as string | null
  );
  const [endAddressIDs, setEndAddressIDs] = React.useState(
    [] as string[]
  );
  const [externalID, setExternalID] = React.useState('');
  const [returnToStart, setReturnToStart] = React.useState(false);
  const [earliestPickupTime, setEarliestPickupTime] = React.useState(
    null as string | null
  );

  const [skipPincodeVerification, setSkipPincodeVerification] =
    React.useState(false);

  const [externalIDValid, setExternalIDValid] = React.useState(
    null as null | boolean
  );
  const [numFlowerRolls, setNumFlowerRolls] = React.useState(0);

  const isAdmin = React.useContext(IsAdminContext);
  const shouldShowPrices =
    isAdmin ||
    !featureGateInUse(featureGates.hidePricesFromNonAdmins);

  const reloadAddresses = (): void => {
    getAddresses().then((data) => {
      const byID: AddressesByID = {};
      for (const address of data) {
        if (address.addressID) byID[address.addressID] = address;
      }
      setAddresses(data);
      setAddressesByID(byID);
    });
  };

  React.useEffect(() => {
    getAddresses().then((data) => {
      const byID: AddressesByID = {};
      for (const address of data) {
        if (address.addressID) byID[address.addressID] = address;
      }
      setAddresses(data);
      setAddressesByID(byID);
    });

    return (): void => {
      // Intentionally empty
    };
  }, []);

  const performValidateExternalID = React.useCallback(
    debounce((val: string): void => {
      setExternalIDValid(null);
      validateExternalID(val).then(setExternalIDValid);
    }, 500),
    [setExternalIDValid]
  );

  React.useEffect(() => {
    setExternalIDValid(null);
    if (externalID) performValidateExternalID(externalID);
    return (): void => {
      // Intentionally empty
    };
  }, [performValidateExternalID, externalID]);

  const addEndAddress = (addressID: string): void => {
    setEndAddressIDs([
      ...endAddressIDs.filter((id) => id !== addressID),
      addressID,
    ]);
  };
  const removeEndAddress = (addressID: string): void => {
    setEndAddressIDs(endAddressIDs.filter((id) => id !== addressID));
  };

  const refreshPrice = async (): Promise<void> => {
    if (!startAddressID || !shouldShowPrices) return;
    const price = await getPrice(
      startAddressID,
      endAddressIDs,
      returnToStart,
      numFlowerRolls > 0 ? 'flowerRoll' : 'normal',
      'sameday',
      numFlowerRolls
    );
    setCurrentPrice(price.price.withoutVat);
    setCurrency(price.price.currency);
  };

  const next = async (): Promise<void> => {
    if (stepNo === 1) {
      if (!startAddressID) {
        return;
      }
      setStepNo(2);
    } else if (stepNo === 2) {
      if (endAddressIDs.length === 0) {
        return;
      }
      setLoading(true);
      await refreshPrice();
      setStepNo(3);
      setLoading(false);
    } else if (stepNo === 3) {
      setLoading(true);
      await refreshPrice();
      setStepNo(4);
      setLoading(false);
    }
  };

  const prev = (): void => {
    if (stepNo > 1) setStepNo(stepNo - 1);
  };

  const sendOrder = async (): Promise<void> => {
    if (!startAddressID || endAddressIDs.length === 0) {
      return;
    }
    setLoading(true);
    try {
      await createOrder(
        externalID || generateUniqueExternalID(),
        startAddressID,
        endAddressIDs,
        returnToStart,
        numFlowerRolls > 0 ? 'flowerRoll' : 'normal',
        'sameday',
        skipPincodeVerification,
        earliestPickupTime,
        numFlowerRolls
      );
      setLoading(false);
      setChosenView('history');
    } catch (err) {
      const externalIDValid = await validateExternalID(externalID);
      setLoading(false);
      const errMsg = externalIDValid
        ? transl('UnknownReason')
        : transl('OrderNumberInvalidMsg');
      alert(transl('OrderSendingFailed') + errMsg);
      if (!externalIDValid) setStepNo(3);
    }
  };

  const priceFormatted =
    currentPrice && currency === 'EUR'
      ? currentPrice
          .toFixed(2)
          .split('.')
          .join(transl('DecimalSeparator'))
      : currentPrice?.toString();

  if (addresses === null) {
    return <LoadingComponent />;
  }
  return (
    <div style={commonStyles.flexColumnContainer}>
      <ScrollableFlexContainer>
        <StartAddressPanel
          addresses={addresses}
          addressesByID={addressesByID}
          chosenAddressID={startAddressID}
          setChosenAddressID={setStartAddressID}
          isExpanded={stepNo === 1}
          transl={transl}
        />
        <EndAddressesPanel
          addresses={addresses}
          addressesByID={addressesByID}
          chosenAddresses={endAddressIDs}
          addAddress={addEndAddress}
          removeAddress={removeEndAddress}
          isExpanded={stepNo === 2}
          transl={transl}
          reloadAddresses={reloadAddresses}
        />
        <OrderPanel
          isExpanded={stepNo === 3}
          externalID={externalID}
          setExternalID={setExternalID}
          externalIDValid={externalIDValid}
          returnToStartAfterComplete={returnToStart}
          setReturnToStartAfterComplete={setReturnToStart}
          transl={transl}
          skipPincodeVerification={skipPincodeVerification}
          setSkipPincodeVerification={setSkipPincodeVerification}
          pickupTime={earliestPickupTime}
          setPickupTime={setEarliestPickupTime}
          numFlowerRolls={numFlowerRolls}
          setNumFlowerRolls={setNumFlowerRolls}
        />
      </ScrollableFlexContainer>

      <MobileKeyboardHidingWrapper>
        <div className="container-fluid" style={styles.footer}>
          <div className="row">
            <div className="col-sm-5">
              {currentPrice !== null && shouldShowPrices && (
                <h1 className="text text-center normaltxt-on-mobile">
                  {priceFormatted}&nbsp;{currencyToSymbol(currency)}
                </h1>
              )}
            </div>
            <div className="col-sm-7">
              <div style={commonStyles.flexRowContainer}>
                <div style={commonStyles.defaultContainer}>
                  {stepNo > 1 && (
                    <button
                      className="button-standard"
                      onClick={prev}
                    >
                      {transl('Previous')}&nbsp;&nbsp;
                      <i
                        className="fa fa-undo"
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                </div>
                <div style={commonStyles.defaultContainer}>
                  {stepNo < 4 && (
                    <button
                      className="button-standard"
                      onClick={next}
                    >
                      {transl('Next')}&nbsp;&nbsp;
                      <i
                        className="fa fa-check"
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                  {stepNo >= 4 && (
                    <button
                      className="button-standard"
                      onClick={sendOrder}
                    >
                      {transl('SendOrder')}&nbsp;&nbsp;
                      <i
                        className="fa fa-check"
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MobileKeyboardHidingWrapper>
      <Spinner visible={loading} />
    </div>
  );
};

const styles = {
  footer: {
    backgroundColor: colors.main,
    position: 'relative' as 'relative',
    margin: 0,
    padding: '15px',
  },
};
