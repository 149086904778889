export const BASE_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const IS_TEST_SERVER =
  window.location.hostname === 'localhost' ||
  window.location.pathname.includes('testing');

export const BASE_URL = IS_TEST_SERVER
  ? 'https://testing.cabapp.fi'
  : 'https://api.cabapp.fi';
