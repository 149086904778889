import React, { Component } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

type Props = {
  style: any;
  onRequestClose: () => void;
  isOpen: boolean;
  contentLabel: string;
  shouldCloseOnOverlayClick: boolean;
  transl: (label: string) => string;
};

type State = {
  popupStyles: any;
};

class NewModal extends Component<Props, State> {
  constructor(props) {
    super(props);

    const pStyle = this.props.style;
    let overlayStyle = Object.assign(
      {},
      styles.overlay,
      pStyle.overlay
    );
    let contentStyle = Object.assign(
      {},
      styles.content,
      pStyle.content
    );
    if (document.body.clientWidth < 800) {
      overlayStyle = Object.assign(
        {},
        overlayStyle,
        mobilestyles.overlay
      );
      contentStyle = Object.assign(
        {},
        contentStyle,
        mobilestyles.content
      );
    }
    this.state = {
      popupStyles: {
        overlay: overlayStyle,
        content: contentStyle,
      },
    };
  }

  componentDidMount(): void {
    if (document.body.clientWidth < 800) return;
    const newS = {
      overlay: Object.assign({}, this.state.popupStyles.overlay, {
        opacity: 1,
        visibility: 'visible',
        transition: 'opacity 0.2s 0s, visibility 0s 0s',
      }),
      content: Object.assign({}, this.state.popupStyles.content, {
        transform: 'translate(-50%, -50%) scale(1)',
      }),
    };
    setTimeout(() => {
      this.setState({
        popupStyles: newS,
      });
    }, 10);
  }

  onRequestClose(): void {
    const pStyle = this.props.style;
    this.setState({
      popupStyles: {
        overlay: Object.assign({}, styles.overlay, pStyle.overlay),
        content: Object.assign({}, styles.content, pStyle.content),
      },
    });

    const close = (): void => {
      this.props.onRequestClose();
    };

    setTimeout(() => {
      close();
    }, 300);
  }

  render(): JSX.Element {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.onRequestClose.bind(this)}
        style={this.state.popupStyles}
        contentLabel={this.props.contentLabel}
        shouldCloseOnOverlayClick={
          this.props.shouldCloseOnOverlayClick
        }
      >
        {document.body.clientWidth < 800 && (
          <button
            type="button"
            className="btn btn-warning btn-lg btn-block"
            style={{ textAlign: 'left', paddingLeft: '20px' }}
            onClick={this.onRequestClose.bind(this)}
          >
            <i className="fa fa-times"></i>&nbsp;&nbsp;
            {this.props.transl('ClosePopup')}
          </button>
        )}
        {this.props.children}
      </Modal>
    );
  }
}

const styles = {
  overlay: {
    zIndex: 1000,
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.2s 0s, visibility 0s 0.2s',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%) scale(0.8)',
    transition: 'all 0.2s ease-in-out',
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
};

const mobilestyles = {
  overlay: {
    opacity: 1,
    visibility: 'visible',
    transition: 'opacity 0.2s 0s, visibility 0s 0s',
  },
  content: {
    height: document.body.clientHeight,
    maxWidth: document.body.clientWidth,
    top: '0px',
    left: '0px',
    marginRight: '0px',
    transform: 'none',
    transition: 'none',
  },
};

export default NewModal;
