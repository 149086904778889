import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import { User } from './types';
import { PersonalSettings } from './PersonalSettings';
import { UserView } from './UserView';
import { AddressesView } from './AddressesView';

export const SettingsPage = ({
  transl,
  personalData,
}: {
  transl: (label: string) => string;
  personalData: User;
}): JSX.Element => {
  const [chosenView, setChosenView] = React.useState('personal');
  return (
    <div style={commonStyles.defaultContainer}>
      <div className="btn-group">
        <button
          type="button"
          onClick={(): void => setChosenView('personal')}
          className={
            'btn btn-primary' +
            (chosenView === 'personal' ? ' active' : '')
          }
        >
          {transl('PersonalSettings')}
        </button>
        <button
          type="button"
          onClick={(): void => setChosenView('addresses')}
          className={
            'btn btn-primary' +
            (chosenView === 'addresses' ? ' active' : '')
          }
        >
          {transl('AddressRegister')}
        </button>
        {personalData.isAdmin && (
          <button
            type="button"
            onClick={(): void => setChosenView('users')}
            className={
              'btn btn-primary' +
              (chosenView === 'users' ? ' active' : '')
            }
          >
            {transl('UserRegister')}
          </button>
        )}
      </div>
      {chosenView === 'personal' && (
        <PersonalSettings
          transl={transl}
          personalData={personalData}
        />
      )}
      {chosenView === 'users' && (
        <UserView transl={transl} personalData={personalData} />
      )}
      {chosenView === 'addresses' && (
        <AddressesView transl={transl} />
      )}
    </div>
  );
};
