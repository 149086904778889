import Gateway from '../web/gateway';

export type SpecialOrder = {
  id?: string;
  name: string;
  providerCompanyBusinessID: string | null;
  merchantPrice: number;
  merchantSundayExtra: number;
  orderCreateFields: {
    allowLeaveAtDoor: boolean;
    backupAddress: string | null;
    backupAddressPostalCode: string | null;
    backupPhone: string | null;
    city: string;
    externalID: string;
    numPackages: number;
    providerCompanyBusinessID: string;
    providerCompanyName: string;
    recipientAddress: string;
    recipientAddressNotes: string;
    recipientEmail: string;
    recipientName: string;
    recipientPhoneNo: string;
    recipientPostalCode: string;
    startAddress: string;
    startAddressNotes: string;
    startAddressPhone: string;
    startAddressTitle: string;
    startPostalCode: string;
  };
};

export const listSpecialOrders = async (): Promise<
  SpecialOrder[]
> => {
  return await Gateway.get('/providercompany/special-orders');
};

export const createOrderFromSpecialOrder = async (
  specialOrderID: string,
  earliestPickupTime: string | null,
  externalID: string | null,
  numPackages: number
): Promise<void> => {
  await Gateway.post('/providercompany/special-orders/create-order', {
    data: {
      specialOrderID,
      earliestPickupTime,
      externalID,
      numPackages,
    },
  });
};
