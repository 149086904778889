import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import { notify } from 'lib/Notifier';
import Spinner from 'lib/Spinner';
import { User } from './types';
import Gateway from '../web/gateway';

export const PersonalSettings = ({
  transl,
  personalData,
}: {
  transl: (label: string) => string;
  personalData: User;
}): JSX.Element => {
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [newPassword2, setNewPassword2] = React.useState('');

  const [loading, setLoading] = React.useState(false);

  const emptyFields = (): void => {
    setOldPassword('');
    setNewPassword('');
    setNewPassword2('');
  };

  const canChangePassword = (): boolean => {
    if (!oldPassword || !newPassword || !newPassword2) return false;
    if (newPassword !== newPassword2) return false;
    return true;
  };

  const changePassword = (): void => {
    if (!canChangePassword()) return;
    setLoading(true);
    Gateway.post('/providercompany/users/me/password', {
      data: { oldPassword, newPassword },
    })
      .then(() => {
        setLoading(false);
        emptyFields();
        notify(transl('PasswordChangeSuccess'));
      })
      .catch(() => {
        setLoading(false);
        emptyFields();
        notify(transl('PasswordChangeFailure'));
      });
  };
  return (
    <div style={commonStyles.flexColumnContainer}>
      <h5>
        {transl('Email')}: {personalData.email}
      </h5>
      <div style={{ maxWidth: '400px' }}>
        <h4>{transl('ChangePassword')}</h4>
        <div className="form-group">
          <label htmlFor="personalsettings-oldPassword">
            {transl('OldPassword')}
          </label>
          <input
            type="password"
            value={oldPassword}
            className="form-control"
            id="personalsettings-oldPassword"
            onChange={(e): void => setOldPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="personalsettings-newPassword">
            {transl('NewPassword')}
          </label>
          <input
            type="password"
            value={newPassword}
            className="form-control"
            id="personalsettings-newPassword"
            onChange={(e): void => setNewPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="personalsettings-newPassword2">
            {transl('NewPassword2')}
          </label>
          <input
            type="password"
            value={newPassword2}
            className="form-control"
            id="personalsettings-newPassword2"
            onChange={(e): void => setNewPassword2(e.target.value)}
          />
        </div>
        <button
          type="button"
          className="btn-lg btn-block button-standard"
          onClick={changePassword}
          disabled={!canChangePassword()}
        >
          {transl('ChangePassword')}
        </button>
      </div>
      {loading && <Spinner visible={true} />}
    </div>
  );
};
