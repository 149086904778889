import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import { LoadingComponent } from 'lib/LoadingComponent';
import { ScrollableFlexContainer } from 'lib/ScrollableFlexContainer';
import { MobileKeyboardHidingWrapper } from 'lib/MobileKeyboardHidingWrapper';
import Spinner from 'lib/Spinner';
import colors from 'lib/colors';
import moment from 'moment';
import { featureGateInUse, featureGates } from '../featureGates';
import { User } from '../settings/types';

import { Address } from '../settings/types';
import { AddressSavingType } from './types';
import { AddressPanel } from './AddressPanel';
import { OrderPanel } from './OrderPanel';
import { SynlabEndAddressPanel } from './SynlabEndAddressPanel';
import Gateway from '../web/gateway';
import {
  startAddressValidation,
  recipientAddressValidation,
  retrievePricingForJourney,
  generateUniqueExternalID,
  validateExternalID,
  saveAddress,
  nowIsOutsideHelsinkiBusinessHours,
  currencyToSymbol,
} from './helpers';
import { formatAddressForSaving } from '../settings/helpers';
import { IsAdminContext } from '../contexts';

export const OrderPage = ({
  transl,
  setChosenView,
  personalData,
}: {
  transl: (label: string) => string;
  setChosenView: (view: string) => void;
  personalData: User;
}): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const [stepNo, setStepNo] = React.useState(1);
  const [addresses, setAddresses] = React.useState(
    null as Address[] | null
  );
  const [currentPrice, setCurrentPrice] = React.useState(
    null as number | null
  );
  const [currency, setCurrency] = React.useState('EUR');

  React.useEffect(() => {
    Gateway.get('/providercompany/addresses').then(setAddresses);
    return (): void => {
      // Intentionally empty
    };
  }, []);

  const [startAddress, setStartAddress] = React.useState(
    null as Address | null
  );
  const [startAddressSavingType, setStartAddressSavingType] =
    React.useState(null as AddressSavingType);
  const [startAddressErrorMsg, setStartAddressErrorMsg] =
    React.useState(null as string | null);

  const [endAddress, setEndAddress] = React.useState(
    null as Address | null
  );
  const [endAddressSavingType, setEndAddressSavingType] =
    React.useState(null as AddressSavingType);
  const [endAddressErrorMsg, setEndAddressErrorMsg] = React.useState(
    null as string | null
  );

  const [externalID, setExternalID] = React.useState('');
  const [nofPackages, setNofPackages] = React.useState(1);
  const [allowLeaveAtDoor, setAllowLeaveAtDoor] =
    React.useState(false);
  const [skipPincodeVerification, setSkipPincodeVerification] =
    React.useState(false);
  const [returnToStartAfterComplete, setReturnToStartAfterComplete] =
    React.useState(false);
  const [isColdDelivery, setIsColdDelivery] = React.useState(false);
  const [isCriticallyUrgent, setIsCriticallyUrgent] = React.useState(
    featureGateInUse(featureGates.teliaCustomizations) &&
      nowIsOutsideHelsinkiBusinessHours()
  );

  const [pickupTime, setPickupTime] = React.useState(
    null as string | null
  );
  const [packageType, setPackageType] = React.useState<
    'normal' | 'pallet' | 'eurPallet' | 'finPallet'
  >('normal');
  const [serviceLevel, setServiceLevel] = React.useState<
    'samehour' | 'sameday'
  >('samehour');

  const [
    synlabEndAddressPanelVisible,
    setSynlabEndAddressPanelVisible,
  ] = React.useState(
    featureGateInUse(featureGates.synlabCustomizations)
  );
  const showSynlabEndAddressPanel = (): void => {
    setSynlabEndAddressPanelVisible(true);
  };
  const hideSynlabEndAddressPanel = (): void => {
    setSynlabEndAddressPanelVisible(false);
    setEndAddress(null);
    setEndAddressSavingType(null);
  };

  const isAdmin = React.useContext(IsAdminContext);
  const shouldShowPrices =
    isAdmin ||
    !featureGateInUse(featureGates.hidePricesFromNonAdmins);

  const refreshPrice = async (): Promise<void> => {
    if (!startAddress || !endAddress || !shouldShowPrices) return;
    const pricing = await retrievePricingForJourney(
      startAddress,
      endAddress,
      pickupTime || moment().toISOString(),
      nofPackages,
      packageType,
      serviceLevel
    );
    setCurrentPrice(pricing.price.withoutVat);
    setCurrency(pricing.price.currency);
  };

  const next = async (): Promise<void> => {
    if (stepNo === 1) {
      if (!startAddress) {
        setStartAddressErrorMsg(
          transl('AsteriskMarkedFieldsAreMandatory')
        );
        return;
      }
      setLoading(true);
      const errorMsg = await startAddressValidation(
        startAddress,
        transl
      );
      setStartAddressErrorMsg(errorMsg);
      setLoading(false);
      if (errorMsg === null) {
        setStepNo(2);
      }
    } else if (stepNo === 2) {
      if (!endAddress) {
        setEndAddressErrorMsg(
          transl('AsteriskMarkedFieldsAreMandatory')
        );
        return;
      }
      setLoading(true);
      const errorMsg = await recipientAddressValidation(
        endAddress,
        transl
      );
      setEndAddressErrorMsg(errorMsg);
      setLoading(false);
      if (errorMsg === null) {
        setStepNo(3);
        refreshPrice();
      }
    } else if (stepNo === 3) {
      let extID = externalID;
      if (!extID) {
        extID = generateUniqueExternalID();
        setExternalID(extID);
      }
      setLoading(true);
      const externalIDValid = await validateExternalID(extID);
      if (externalIDValid) {
        await refreshPrice();
        setStepNo(4);
      }
      setLoading(false);
    }
  };

  const prev = (): void => {
    if (stepNo > 1) setStepNo(stepNo - 1);
  };

  const sendOrder = async (): Promise<void> => {
    if (!startAddress || !endAddress || !externalID) return;
    setLoading(true);
    try {
      await Promise.all([
        saveAddress(startAddress, startAddressSavingType),
        saveAddress(endAddress, endAddressSavingType),
      ]);
      const start = formatAddressForSaving(startAddress);
      const end = formatAddressForSaving(endAddress);

      await Gateway.post('/providercompany/orders', {
        data: {
          externalID,
          startAddressTitle: start.title,
          startAddress: start.shortAddress,
          startPostalCode: start.postalCode,
          startAddressNotes: start.notes,
          startAddressPhone: start.phone,
          recipientName: end.title,
          recipientAddress: end.shortAddress,
          recipientPostalCode: end.postalCode,
          recipientPhoneNo: end.phone,
          recipientEmail: end.email,
          recipientAddressNotes: end.notes,
          allowLeaveAtDoor,
          numPackages: nofPackages,
        },
        meta: {
          orderTimingType:
            pickupTime !== null ? 'timed' : 'immediate',
          earliestPickupTime: pickupTime,
          packageType,
          serviceLevel,
          skipPincodeVerification,
          returnToStartAfterComplete,
          isColdDelivery,
          isCriticallyUrgent:
            featureGateInUse(featureGates.teliaCustomizations) &&
            nowIsOutsideHelsinkiBusinessHours()
              ? true
              : isCriticallyUrgent,
        },
      });
      setLoading(false);
      setChosenView('history');
    } catch (err) {
      const externalIDValid = await validateExternalID(externalID);
      setLoading(false);
      const errMsg = externalIDValid
        ? transl('UnknownReason')
        : transl('OrderNumberInvalidMsg');
      alert(transl('OrderSendingFailed') + errMsg);
      if (!externalIDValid) setStepNo(3);
    }
  };

  const priceFormatted =
    currentPrice && currency === 'EUR'
      ? currentPrice
          .toFixed(2)
          .split('.')
          .join(transl('DecimalSeparator'))
      : currentPrice?.toString();

  if (addresses === null) {
    return <LoadingComponent />;
  }
  return (
    <div style={commonStyles.flexColumnContainer}>
      <ScrollableFlexContainer>
        <AddressPanel
          values={startAddress}
          isExpanded={stepNo === 1}
          title={transl('PickupAddress')}
          imgUrl={'/img/sender.png'}
          addresses={addresses}
          setValues={setStartAddress}
          transl={transl}
          error={startAddressErrorMsg}
          panelID={'startaddresspanel'}
          isStartAddress={true}
          addressSavingType={startAddressSavingType}
          setAddressSavingType={setStartAddressSavingType}
        />
        {featureGateInUse(featureGates.synlabCustomizations) &&
        synlabEndAddressPanelVisible ? (
          <SynlabEndAddressPanel
            values={endAddress}
            isExpanded={stepNo === 2}
            title={transl('Recipient')}
            imgUrl={'/img/recipient.png'}
            setValues={setEndAddress}
            transl={transl}
            hideSynlabEndAddressPanel={hideSynlabEndAddressPanel}
            isAdmin={personalData.isAdmin}
          />
        ) : (
          <AddressPanel
            values={endAddress}
            isExpanded={stepNo === 2}
            title={transl('Recipient')}
            imgUrl={'/img/recipient.png'}
            addresses={addresses}
            setValues={setEndAddress}
            transl={transl}
            error={endAddressErrorMsg}
            panelID={'endaddresspanel'}
            isStartAddress={false}
            addressSavingType={endAddressSavingType}
            setAddressSavingType={setEndAddressSavingType}
            showSynlabEndAddressPanel={showSynlabEndAddressPanel}
            isAdmin={personalData.isAdmin}
          />
        )}
        <OrderPanel
          isExpanded={stepNo === 3}
          externalID={externalID}
          nofPackages={nofPackages}
          allowLeaveAtDoor={allowLeaveAtDoor}
          transl={transl}
          setExternalID={setExternalID}
          setNofPackages={setNofPackages}
          setAllowLeaveAtDoor={setAllowLeaveAtDoor}
          pickupTime={pickupTime}
          setPickupTime={setPickupTime}
          packageType={packageType}
          setPackageType={setPackageType}
          serviceLevel={serviceLevel}
          setServiceLevel={setServiceLevel}
          skipPincodeVerification={skipPincodeVerification}
          setSkipPincodeVerification={setSkipPincodeVerification}
          returnToStartAfterComplete={returnToStartAfterComplete}
          setReturnToStartAfterComplete={
            setReturnToStartAfterComplete
          }
          isColdDelivery={isColdDelivery}
          setIsColdDelivery={setIsColdDelivery}
          isCriticallyUrgent={isCriticallyUrgent}
          setIsCriticallyUrgent={setIsCriticallyUrgent}
        />
      </ScrollableFlexContainer>

      <MobileKeyboardHidingWrapper>
        <div className="container-fluid" style={styles.footer}>
          <div className="row">
            <div className="col-sm-5">
              {currentPrice !== null && shouldShowPrices && (
                <h1 className="text text-center normaltxt-on-mobile">
                  {priceFormatted}&nbsp;{currencyToSymbol(currency)}
                </h1>
              )}
            </div>
            <div className="col-sm-7">
              <div style={commonStyles.flexRowContainer}>
                <div style={commonStyles.defaultContainer}>
                  {stepNo > 1 && (
                    <button
                      className="button-standard"
                      onClick={prev}
                    >
                      {transl('Previous')}&nbsp;&nbsp;
                      <i
                        className="fa fa-undo"
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                </div>
                <div style={commonStyles.defaultContainer}>
                  {stepNo < 4 && (
                    <button
                      className="button-standard"
                      onClick={next}
                    >
                      {transl('Next')}&nbsp;&nbsp;
                      <i
                        className="fa fa-check"
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                  {stepNo >= 4 && (
                    <button
                      className="button-standard"
                      onClick={sendOrder}
                    >
                      {transl('SendOrder')}&nbsp;&nbsp;
                      <i
                        className="fa fa-check"
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MobileKeyboardHidingWrapper>
      <Spinner visible={loading} />
    </div>
  );
};

const styles = {
  footer: {
    backgroundColor: colors.main,
    position: 'relative' as 'relative',
    margin: 0,
    padding: '15px',
  },
};
