import React from 'react';
import { AddressPopup } from '../settings/AddressPopup';
import { Address } from '../settings/types';

export const NewAddressPopup = ({
  transl,
  closePopup,
  onSave,
  reloadAddresses,
}: {
  transl: (label: string) => string;
  closePopup: () => void;
  onSave?: (attrs: Address) => any;
  reloadAddresses: () => any;
}): JSX.Element => {
  return (
    <AddressPopup
      transl={transl}
      reloadAddresses={reloadAddresses}
      closePopup={closePopup}
      attrs={{
        title: '',
        shortAddress: '',
        postalCode: '',
        notes: '',
        phone: '',
        email: null,
      }}
      onSave={onSave}
    />
  );
};
