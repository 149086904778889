import React from 'react';
import FixedTable from 'lib/FixedTable';
import Spinner from 'lib/Spinner';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import { dynamoKeysToBase32 } from 'lib/helpers';
// import { UserPopup } from './UserPopup';
import { User } from './types';
import { notify } from 'lib/Notifier';
import { NewUserPopup } from './NewUserPopup';

import Gateway from '../web/gateway';

type UserListItemProps = {
  attrs: User;
  reloadUsers: () => void;
  transl: (label: string) => string;
  setIsLoading: (loading: boolean) => void;
  isSelf: boolean;
  key: any;
};

const UserListItem = ({
  attrs,
  reloadUsers,
  transl,
  setIsLoading,
  isSelf,
}: UserListItemProps): JSX.Element => {
  const toggleIsAdmin = (): void => {
    if (
      !window.confirm(
        transl(
          attrs.isAdmin ? 'ConfirmDemoteUser' : 'ConfirmPromoteUser'
        )
          .split('#EMAIL#')
          .join(attrs.email)
      )
    ) {
      return;
    }

    setIsLoading(true);
    Gateway.post(
      `/providercompany/users/id/${dynamoKeysToBase32(attrs.email)}`,
      { data: { isAdmin: !attrs.isAdmin } }
    )
      .then(() => {
        setIsLoading(false);
        reloadUsers();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const resetCredentials = (): void => {
    if (
      !window.confirm(
        transl('ConfirmResetUser').split('#EMAIL#').join(attrs.email)
      )
    ) {
      return;
    }
    setIsLoading(true);
    Gateway.post(
      `/providercompany/users/id/${dynamoKeysToBase32(
        attrs.email
      )}/reset`,
      {}
    )
      .then(() => {
        setIsLoading(false);
        notify(
          transl('ResetUserSuccess')
            .split('#EMAIL#')
            .join(attrs.email)
        );
        reloadUsers();
      })
      .catch(() => {
        setIsLoading(false);
        notify(
          transl('ResetUserFailure')
            .split('#EMAIL#')
            .join(attrs.email)
        );
      });
  };
  const deleteUser = (): void => {
    if (
      !window.confirm(
        transl('ConfirmDeleteUser').split('#EMAIL#').join(attrs.email)
      )
    ) {
      return;
    }
    setIsLoading(true);
    Gateway.del(
      `/providercompany/users/id/${dynamoKeysToBase32(attrs.email)}`
    )
      .then(() => {
        setIsLoading(false);
        reloadUsers();
        notify(
          transl('DeleteUserSuccess')
            .split('#EMAIL#')
            .join(attrs.email)
        );
      })
      .catch(() => {
        setIsLoading(false);
        notify(
          transl('DeleteUserFailure')
            .split('#EMAIL#')
            .join(attrs.email)
        );
      });
  };
  return (
    <tr>
      <td>{attrs.email}</td>
      <td>
        {attrs.isAdmin ? transl('Yes') : transl('No')}&nbsp;&nbsp;
        {!isSelf && (
          <button
            type="button"
            className="btn btn-primary btn-md"
            onClick={toggleIsAdmin}
          >
            &nbsp;&nbsp;{' '}
            {transl(attrs.isAdmin ? 'DemoteUser' : 'PromoteUser')}
          </button>
        )}
      </td>
      <td>
        {!isSelf && (
          <button
            type="button"
            className="btn btn-warning btn-md"
            onClick={resetCredentials}
          >
            &nbsp;&nbsp;{transl('ResetUser')}
          </button>
        )}
      </td>
      <td>
        {!isSelf && (
          <button
            type="button"
            className="btn btn-danger btn-md"
            onClick={deleteUser}
          >
            &nbsp;&nbsp;{transl('DeleteUser')}
          </button>
        )}
      </td>
    </tr>
  );
};

export const UserView = ({
  transl,
  personalData,
}: {
  transl: (label: string) => string;
  personalData: User;
}): JSX.Element => {
  const [users, setUsers] = React.useState(null as User[] | null);
  const [newUserPopupOpen, setNewUserPopupOpen] = React.useState(
    false
  );
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    Gateway.get('/providercompany/users').then((data) =>
      setUsers(data)
    );
    return (): void => {
      // Intentionally empty
    };
  }, []);

  const reloadUsers = (): void => {
    Gateway.get('/providercompany/users').then((data) =>
      setUsers(data)
    );
  };

  const openNewUserPopup = (): void => {
    setNewUserPopupOpen(true);
  };

  if (users === null) {
    return <LoadingComponent />;
  }
  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ paddingTop: '15px' }}>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={openNewUserPopup}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;{transl('NewUser')}
        </button>
      </div>
      <div style={commonStyles.flexColumnContainer}>
        <FixedTable
          header={
            <tr>
              <th>{transl('Email')}</th>
              <th>{transl('IsAdmin')}</th>
              <th>{transl('ResetUser')}</th>
              <th>{transl('DeleteUser')}</th>
            </tr>
          }
        >
          {users.map((attrs, i) => {
            return (
              <UserListItem
                key={i}
                attrs={attrs}
                reloadUsers={reloadUsers}
                transl={transl}
                setIsLoading={setIsLoading}
                isSelf={attrs.email === personalData.email}
              />
            );
          })}
        </FixedTable>
      </div>
      {newUserPopupOpen && (
        <NewUserPopup
          transl={transl}
          reloadUsers={reloadUsers}
          setIsLoading={setIsLoading}
          closePopup={(): void => setNewUserPopupOpen(false)}
        />
      )}
      <Spinner visible={isLoading} />
    </div>
  );
};
