import React from 'react';

const Chevron = ({
  className,
  fill,
  height,
  width,
}: {
  className: string;
  fill: string;
  height: number;
  width: number;
}): JSX.Element => {
  return (
    <svg
      className={className}
      height={height}
      width={width}
      viewBox="0 0 266 438"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m258.476 235.971-194.344 194.343c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901l154.021-154.746-154.021-154.745c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0l194.343 194.343c9.373 9.372 9.373 24.568.001 33.941z"
        fill={fill}
      />
    </svg>
  );
};

type Props = {
  isExpanded: boolean;
  imgUrl: string;
  title: string;
  titleTextAlign: 'center' | 'left' | 'right';
  children: any;
  headerExpansionItem: any;
  accordionExtraClassName?: string;
};

export const Accordion = ({
  isExpanded,
  imgUrl,
  children,
  headerExpansionItem,
  title,
  titleTextAlign,
  accordionExtraClassName,
}: Props): JSX.Element => {
  const [setHeight, setHeightState] = React.useState('0px');
  const [setRotate, setRotateState] = React.useState(
    'accordion__icon'
  );

  React.useEffect(() => {
    setHeightState(!isExpanded ? '0px' : 'auto');
    setRotateState(
      !isExpanded ? 'accordion__icon' : 'accordion__icon rotate'
    );
    return (): void => {
      // Intentionally empty
    };
  }, [isExpanded]);

  return (
    <div className="accordion__section">
      <div className={`accordion ${accordionExtraClassName || ''}`}>
        <div className="accordion__title__container">
          <img className="accordion__titleimg" src={imgUrl} alt="" />
          <div
            className="accordion__title"
            style={{ textAlign: titleTextAlign }}
          >
            {title}
          </div>
          <Chevron
            className={`${setRotate}`}
            width={30}
            height={30}
            fill={'#eee'}
          />
        </div>
        {headerExpansionItem}
      </div>
      <div
        style={{
          height: `${setHeight}`,
          overflow: setHeight === 'auto' ? 'visible' : 'hidden',
        }}
        className="accordion__content"
      >
        {children}
      </div>
    </div>
  );
};
