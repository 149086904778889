import React from 'react';
import { Accordion } from 'lib/Accordion';
import colors from 'lib/colors';
import Select from 'react-virtualized-select';

import { Address } from '../settings/types';
import { formatAddress } from '../settings/helpers';
import { AddressesByID } from './requests';
import { NewAddressPopup } from './NewAddressPopup';

const HeaderExpansionItem = ({
  addressesByID,
  chosenAddresses,
}: {
  addressesByID: AddressesByID;
  chosenAddresses: string[];
}): JSX.Element => {
  return (
    <div
      className="container-fluid"
      style={styles.expansionContainer}
    >
      {chosenAddresses.map((addressID) => {
        const address = addressesByID[addressID];
        return (
          <div
            className="row"
            style={{
              borderBottom: '1px solid black',
              paddingBottom: '7px',
            }}
            key={addressID}
          >
            <div className="col-sm-6 no-padding-left">
              {address.shortAddress}, {address.postalCode}{' '}
              {address.city}
            </div>
            <div className="col-sm-6 no-padding-left">
              ({address.phone} {address.email})
            </div>
          </div>
        );
      })}
    </div>
  );
};

const getHeaderExpansionItem = (
  chosenAddresses: string[],
  addressesByID: AddressesByID
): JSX.Element => (
  <HeaderExpansionItem
    chosenAddresses={chosenAddresses}
    addressesByID={addressesByID}
  />
);

type Props = {
  addresses: Address[];
  addressesByID: AddressesByID;
  chosenAddresses: string[];
  addAddress: (addressID: string) => any;
  removeAddress: (addressID: string) => any;
  isExpanded: boolean;
  transl: (label: string) => string;
  reloadAddresses: () => any;
};

export const EndAddressesPanel = ({
  addresses,
  addressesByID,
  chosenAddresses,
  addAddress,
  removeAddress,
  isExpanded,
  transl,
  reloadAddresses,
}: Props): JSX.Element => {
  const [newAddressPopupVisible, setNewAddressPopupVisible] =
    React.useState(false);

  const headerExpansionItem = isExpanded
    ? null
    : getHeaderExpansionItem(chosenAddresses, addressesByID);

  const titleTextAlign = isExpanded ? 'center' : 'left';

  const dropdownOptions = addresses.map((item) => ({
    label: formatAddress(item),
    value: item.addressID,
  }));

  return (
    <>
      <Accordion
        titleTextAlign={titleTextAlign}
        isExpanded={isExpanded}
        title={transl('DeliveryAddresses')}
        imgUrl={'/img/recipient.png'}
        headerExpansionItem={headerExpansionItem}
        accordionExtraClassName={''}
      >
        <div className="container-fluid" style={{ padding: '15px' }}>
          <div className="row">
            <div className="col-xs-9">
              <div className="form-group">
                <label>{transl('ChooseAddress')}</label>
                <Select
                  placeholder={transl('ChooseAddress')}
                  options={dropdownOptions}
                  onChange={(newVal: any): void => {
                    if (newVal?.value) addAddress(newVal?.value);
                  }}
                  value={null as any}
                />
              </div>
            </div>
            <div className="col-xs-3">
              <div
                className="form-group"
                style={{ marginTop: '25px' }}
              >
                <button
                  className="btn btn-success btn-md btn-block"
                  onClick={(): void =>
                    setNewAddressPopupVisible(true)
                  }
                >
                  {transl('NewAddress')}
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <h4>{transl('ChosenAddresses')}</h4>
              <div className="form-group">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>{transl('Address')}</th>
                      <th>{transl('Remove')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {chosenAddresses.map((addressID) => {
                      if (!addressesByID[addressID]) return null;
                      return (
                        <tr key={addressID}>
                          <td>
                            {formatAddress(addressesByID[addressID])}
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-danger btn-md"
                              onClick={(): void =>
                                removeAddress(addressID)
                              }
                            >
                              &nbsp;&nbsp;{transl('Remove')}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Accordion>
      {newAddressPopupVisible && (
        <NewAddressPopup
          reloadAddresses={reloadAddresses}
          transl={transl}
          closePopup={(): void => setNewAddressPopupVisible(false)}
          onSave={(address): void => {
            if (address.addressID) addAddress(address.addressID);
          }}
        />
      )}
    </>
  );
};

const styles = {
  expansionContainer: {
    paddingLeft: '75px',
    paddingRight: '75px',
    margin: 0,
  },
  errorMsg: {
    color: colors.danger,
    whiteSpace: 'pre-wrap' as 'pre-wrap',
  },
};
