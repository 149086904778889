import React from 'react';
import { Accordion } from 'lib/Accordion';
import colors from 'lib/colors';
import {
  featureGateInUse,
  featureGates,
  getIsPincodeVerificationRequiredForCompany,
} from '../featureGates';
import { PickupTimeSelector } from '../order/PickupTimeSelector';

const range = (start: number, end: number): number[] => {
  const res: number[] = [];
  for (let i = start; i < end; i++) {
    res.push(i);
  }
  return res;
};

const HeaderExpansionItem = ({
  externalID,
  returnToStartAfterComplete,
  transl,
}: {
  externalID: string;
  returnToStartAfterComplete: boolean;
  transl: (label: string) => string;
}): JSX.Element => {
  return (
    <div
      className="container-fluid"
      style={styles.expansionContainer}
    >
      <div className="row">
        <div className="col-sm-12 no-padding-left">
          {transl('OrderNumber')}: {externalID}
          <br />
          {returnToStartAfterComplete && <br />}
          {returnToStartAfterComplete &&
            transl('ReturnToStartAddress')}
        </div>
      </div>
    </div>
  );
};

const getHeaderExpansionItem = (
  externalID: string,
  returnToStartAfterComplete: boolean,
  transl: (label: string) => string
): JSX.Element => (
  <HeaderExpansionItem
    externalID={externalID}
    transl={transl}
    returnToStartAfterComplete={returnToStartAfterComplete}
  />
);

type Props = {
  isExpanded: boolean;
  externalID: string;
  setExternalID: (val: string) => void;
  externalIDValid: boolean | null;
  returnToStartAfterComplete: boolean;
  setReturnToStartAfterComplete: (val: boolean) => void;
  transl: (label: string) => string;
  skipPincodeVerification: boolean;
  setSkipPincodeVerification: (val: boolean) => void;
  pickupTime: string | null;
  setPickupTime: (time: string | null) => void;
  numFlowerRolls: number;
  setNumFlowerRolls: (arg: number) => void;
};

export const OrderPanel = ({
  isExpanded,
  externalID,
  setExternalID,
  externalIDValid,
  returnToStartAfterComplete,
  setReturnToStartAfterComplete,
  transl,
  skipPincodeVerification,
  setSkipPincodeVerification,
  pickupTime,
  setPickupTime,
  numFlowerRolls,
  setNumFlowerRolls,
}: Props): JSX.Element => {
  const [flowerRollsVisible, setFlowerRollsVisible] =
    React.useState(false);
  const headerExpansionItem = !isExpanded
    ? getHeaderExpansionItem(
        externalID,
        returnToStartAfterComplete,
        transl
      )
    : null;

  const titleTextAlign = isExpanded ? 'center' : 'left';

  const externalIDChanged = (newVal: string): void => {
    setExternalID(newVal.split(' ').join(''));
  };

  return (
    <Accordion
      titleTextAlign={titleTextAlign}
      isExpanded={isExpanded}
      title={transl('Order')}
      imgUrl={'/img/info.png'}
      headerExpansionItem={headerExpansionItem}
    >
      <div className="container-fluid" style={{ padding: '15px' }}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="orderpanel-externalid">
                {transl('OrderNumber')}
              </label>
              <input
                type="text"
                value={externalID}
                className="form-control"
                id="orderpanel-externalid"
                onChange={(e): void =>
                  externalIDChanged(e.target.value)
                }
              />
              {externalIDValid === true && (
                <div style={styles.successMsg}>
                  <i className="fa fa-check"></i>
                  {transl('OrderNumberValidMsg')}
                </div>
              )}
              {externalIDValid === false && (
                <div style={styles.errorMsg}>
                  <i className="fa fa-times"></i>
                  {transl('OrderNumberInvalidMsg')}
                </div>
              )}
              {externalIDValid === null && !!externalID && (
                <div>
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={returnToStartAfterComplete}
                    onChange={(e): void => {
                      const isChecked = (e.target as any).checked;
                      setReturnToStartAfterComplete(isChecked);
                    }}
                  />
                  &nbsp;&nbsp;
                  {transl('ReturnToStartAfterDelivery')}
                </label>
              </div>
              {returnToStartAfterComplete && (
                <p className="text-warning">
                  {transl('ReturnToStartAddressWarningMsg')}
                </p>
              )}
              {getIsPincodeVerificationRequiredForCompany() && (
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      checked={skipPincodeVerification}
                      onChange={(e): void => {
                        const isChecked = (e.target as any).checked;
                        setSkipPincodeVerification(isChecked);
                      }}
                    />
                    &nbsp;&nbsp;
                    {transl('SkipPincodeVerification')}
                  </label>
                </div>
              )}

              {featureGateInUse(
                featureGates.showFlowerRollOption
              ) && (
                <>
                  <div className="form-group">
                    <label>
                      <input
                        type="checkbox"
                        checked={flowerRollsVisible}
                        onChange={(e): void => {
                          const isChecked = (e.target as any).checked;
                          setFlowerRollsVisible(isChecked);
                          if (!isChecked) {
                            setNumFlowerRolls(0);
                          }
                        }}
                      />
                      &nbsp;&nbsp;
                      {transl('OrderFlowerRolls')}
                    </label>
                  </div>
                  {flowerRollsVisible && (
                    <div className="form-group">
                      <label htmlFor="orderpanel-externalid">
                        {transl('NumberOfFlowerRolls')}
                      </label>
                      <select
                        className="form-control"
                        value={numFlowerRolls.toString()}
                        onChange={(e): void => {
                          setNumFlowerRolls(
                            parseInt(e.target.value, 10)
                          );
                        }}
                      >
                        {range(0, 41).map((item) => {
                          return (
                            <option
                              key={item.toString()}
                              value={item.toString()}
                            >
                              {item.toString()}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                </>
              )}
              <PickupTimeSelector
                pickupTime={pickupTime}
                setPickupTime={setPickupTime}
                transl={transl}
              />
            </div>
          </div>
        </div>
      </div>
    </Accordion>
  );
};

const styles = {
  expansionContainer: {
    paddingLeft: '75px',
    paddingRight: '75px',
    margin: 0,
  },
  errorMsg: {
    color: colors.danger,
  },
  successMsg: {
    color: colors.success,
  },
};
