import { synlabLogoUrl } from './synlabCustomizations';

let currentFeatureGates: string[] = [];
let pincodeVerificationRequired = false;
let temperatureMonitoringRequired = false;

export const setFeatureGates = (fgs: string[]): void => {
  currentFeatureGates = fgs;
};

export const setPincodeVerificationRequired = (
  val: boolean
): void => {
  pincodeVerificationRequired = val;
};

export const setTemperatureMonitoringRequired = (
  val: boolean
): void => {
  temperatureMonitoringRequired = val;
};

export const featureGateInUse = (fg: string): boolean =>
  currentFeatureGates.includes(fg);

export const featureGates = {
  synlabCustomizations: 'synlab_customizations',
  showScheduledOrdersInOrderPanel:
    'show_scheduled_orders_in_order_panel',
  showPalletSendingOptionsInOrderPanel:
    'show_pallet_sending_options_in_order_panel',
  showSamedayOrdersInOrderPanel: 'show_sameday_orders_in_order_panel',
  hideRegularOrdersView: 'hide_regular_orders_view',
  advancedTimeSelectionOptions: 'advanced_time_selection_options',
  hidePricesFromNonAdmins: 'hide_prices_from_non_admins',
  showColdDeliveryOption: 'show_cold_delivery_option',
  teliaCustomizations: 'telia_customizations',
  showFlowerRollOption: 'show_flower_roll_option',
};

export const getLogoUrl = (): string => {
  return featureGateInUse(featureGates.synlabCustomizations)
    ? synlabLogoUrl
    : 'https://www.cabapp.fi/img/logo.png';
};

export const getIsPincodeVerificationRequiredForCompany =
  (): boolean => {
    return pincodeVerificationRequired;
  };

export const getIsTemperatureMonitoringRequiredForCompany =
  (): boolean => {
    return temperatureMonitoringRequired;
  };
