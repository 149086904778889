import React, { Component } from 'react';
import colors from './colors';

import { commonStyles } from './commonStyles';

let notifyCallback: ((msg: string) => void) | null = null;

export const notify = (msg: string): void => {
  if (notifyCallback) notifyCallback(msg);
};

type Props = {
  children: any;
};

type State = {
  notifyMsg: string | null;
};

export class Notifier extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      notifyMsg: null,
    };

    notifyCallback = this.notify.bind(this);
  }

  closeNotify(): void {
    this.setState({
      notifyMsg: null,
    });
  }

  notify(msg: string): void {
    this.setState({
      notifyMsg: msg,
    });
    setTimeout(() => {
      this.closeNotify();
    }, 2000);
  }

  render(): any {
    return (
      <React.Fragment>
        {this.props.children}
        {this.state.notifyMsg && (
          <div
            style={Object.assign(
              {},
              commonStyles.fixedContainer,
              styles.container
            )}
            onClick={this.closeNotify.bind(this)}
          >
            <div style={styles.notifyTextContainer}>
              <span style={styles.notifyText}>
                {this.state.notifyMsg}
              </span>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000000,
  },
  notifyTextContainer: {
    borderRadius: '10px',
    padding: '10px',
    backgroundColor: colors.darkMain,
    display: 'inline-block',
  },
  notifyText: {
    fontSize: '25px',
    color: 'white',
  },
};
