import Gateway from '../web/gateway';
import { Address } from '../settings/types';
import { PricingData } from '../order/types';

export const getAddresses = async (): Promise<Address[]> => {
  return await Gateway.get('/providercompany/addresses');
};

export const getPrice = async (
  startAddressID: string,
  endAddressIDs: string[],
  returnToStart: boolean,
  packageType:
    | 'normal'
    | 'pallet'
    | 'flowerRoll'
    | 'eurPallet'
    | 'finPallet',
  serviceLevel: 'samehour' | 'sameday',
  numFlowerRolls: number
): Promise<PricingData> => {
  const data = {
    startAddressID,
    endAddressIDs,
    returnToStart,
    packageType,
    serviceLevel,
    numFlowerRolls,
  };
  return await Gateway.post(
    '/providercompany/multi-address-pricing',
    { data }
  );
};

export const createOrder = async (
  externalID: string,
  startAddressID: string,
  endAddressIDs: string[],
  returnToStart: boolean,
  packageType:
    | 'normal'
    | 'pallet'
    | 'flowerRoll'
    | 'eurPallet'
    | 'finPallet',
  serviceLevel: 'samehour' | 'sameday',
  skipPincodeVerification: boolean,
  earliestPickupTime: string | null,
  numFlowerRolls: number
): Promise<PricingData> => {
  const data = {
    externalID,
    startAddressID,
    endAddressIDs,
    returnToStart,
    packageType,
    serviceLevel,
    skipPincodeVerification,
    earliestPickupTime,
    numFlowerRolls,
  };
  return await Gateway.post(
    '/providercompany/create-multi-address-order',
    { data }
  );
};

export type AddressesByID = { [addressID: string]: Address };
