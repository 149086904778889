import React from 'react';
import colors from 'lib/colors';
import {
  getLogoUrl,
  setFeatureGates,
  featureGateInUse,
  featureGates,
  setPincodeVerificationRequired,
  setTemperatureMonitoringRequired,
} from './featureGates';
import { commonStyles } from 'lib/commonStyles';
import { login, makeRawRequest } from './web/tokens';
import { useEventListener } from './lib/useEventListener';
import { LoadingComponent } from './lib/LoadingComponent';

const CredentialsView = ({
  logUserIn,
  companyBusinessId,
  companyName,
  transl,
}: {
  logUserIn: () => void;
  companyBusinessId: string;
  companyName: string;
  transl: (label: string) => string;
}): JSX.Element => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [hasErrors, setHasErrors] = React.useState(false);

  const loginPressed = (
    e: React.FormEvent<HTMLFormElement>
  ): void => {
    e.preventDefault();
    setLoading(true);
    login(companyBusinessId, email, password)
      .then(logUserIn)
      .catch(() => {
        setLoading(false);
        setHasErrors(true);
      });
  };

  return (
    <div
      style={{
        ...commonStyles.flexColumnContainer,
        ...styles().container,
      }}
    >
      <form
        style={styles().main}
        onSubmit={loginPressed}
        autoComplete="off"
      >
        <div style={styles().logoContainer}>
          <img src={getLogoUrl()} style={styles().logo} alt="" />
        </div>
        <h3 style={commonStyles.textCenter}>
          {transl('LoginToCompany').replace('#COMPANY#', companyName)}
        </h3>
        {hasErrors && (
          <p style={{ color: 'red' }}>
            {transl('IncorrectCredentialsError')}
          </p>
        )}
        <div className="form-group">
          <label htmlFor="loginviewEmail">{transl('Email')}</label>
          <input
            type="text"
            autoComplete="off"
            value={email}
            className="form-control"
            id="loginviewEmail"
            name="loginviewEmail"
            onChange={(e): void => setEmail(e.target.value)}
          />
          <label htmlFor="loginviewpassword">
            {transl('Password')}
          </label>
          <input
            type="password"
            autoComplete="off"
            value={password}
            className="form-control"
            id="loginviewpassword"
            name="loginviewpassword"
            onChange={(e): void => setPassword(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="button-standard btn-lg btn-block"
          disabled={loading}
        >
          {transl('Login')}
        </button>
        <br />
      </form>
    </div>
  );
};

const CompanyBidView = ({
  hasErrors,
  transl,
}: {
  hasErrors: boolean;
  transl: (label: string) => string;
}): JSX.Element => {
  const [companyBusinessId, setCompanyBusinessId] = React.useState(
    window.location.hash.replace('#/', '')
  );

  const setCompanyPressed = (
    e: React.FormEvent<HTMLFormElement>
  ): void => {
    e.preventDefault();
    window.location.hash = '/' + companyBusinessId;
  };

  return (
    <div
      style={{
        ...commonStyles.flexColumnContainer,
        ...styles().container,
      }}
    >
      <form
        style={styles().main}
        onSubmit={setCompanyPressed}
        autoComplete="off"
      >
        <div style={styles().logoContainer}>
          <img src={getLogoUrl()} style={styles().logo} alt="" />
        </div>
        <h3 style={commonStyles.textCenter}>
          {transl('EnterCompanyBid')}
        </h3>
        {hasErrors && (
          <p style={{ color: 'red' }}>
            {transl('CompanyNotFoundError')}
          </p>
        )}
        <div className="form-group">
          <label htmlFor="loginviewcompanybid">
            {transl('CompanyBid')}
          </label>
          <input
            type="text"
            autoComplete="off"
            value={companyBusinessId}
            className="form-control"
            id="loginviewcompanybid"
            name="loginviewcompanybid"
            onChange={(e): void =>
              setCompanyBusinessId(e.target.value)
            }
          />
        </div>
        <button
          type="submit"
          className="button-standard btn-lg btn-block"
        >
          {transl('GoToLogin')}
        </button>
        <br />
      </form>
    </div>
  );
};

const LoginView = ({
  logUserIn,
  transl,
  companyName,
  setCompanyName,
}: {
  logUserIn: () => void;
  transl: (label: string) => string;
  companyName: string | null;
  setCompanyName: (name: string | null) => any;
}): JSX.Element => {
  const [isLoading, setisLoading] = React.useState(false);
  const [companyBusinessId, setCompanyBusinessId] = React.useState(
    window.location.hash.replace('#/', '')
  );

  const handler = React.useCallback((): any => {
    const bid = window.location.hash.replace('#/', '');
    setCompanyBusinessId(bid);
    setisLoading(true);
    makeRawRequest('GET', '/providercompanynames/' + bid)
      .then((res: any) => {
        setFeatureGates(res.features);
        setPincodeVerificationRequired(
          res.pincodeVerificationRequired
        );
        setTemperatureMonitoringRequired(
          res.temperatureMonitoringRequired
        );
        setisLoading(false);
        setCompanyName(res.name);
      })
      .catch(() => {
        setisLoading(false);
        setCompanyName(null);
      });
  }, [setisLoading, setCompanyName, setCompanyBusinessId]);

  useEventListener('hashchange', handler);

  React.useEffect(() => {
    const bid = window.location.hash.replace('#/', '');
    if (bid) {
      setCompanyBusinessId(bid);
      setisLoading(true);
      makeRawRequest('GET', '/providercompanynames/' + bid)
        .then((res: any) => {
          setFeatureGates(res.features);
          setPincodeVerificationRequired(
            res.pincodeVerificationRequired
          );
          setTemperatureMonitoringRequired(
            res.temperatureMonitoringRequired
          );
          setisLoading(false);
          setCompanyName(res.name);
        })
        .catch(() => {
          setisLoading(false);
          setCompanyName(null);
        });
    }
    return (): void => {
      // intentionally empty
    };
  }, [setCompanyName]);

  if (isLoading) return <LoadingComponent />;

  if (!companyName)
    return (
      <CompanyBidView
        hasErrors={companyBusinessId.length > 0}
        transl={transl}
      />
    );

  return (
    <CredentialsView
      logUserIn={logUserIn}
      companyBusinessId={companyBusinessId}
      companyName={companyName}
      transl={transl}
    />
  );
};

const defaultStyles = {
  container: {
    backgroundColor: colors.main,
    alignItems: 'center',
    justifyContent: 'center',
  },
  main: {
    maxWidth: '500px',
    width: '80%',
    margin: 'auto',
    marginTop: '150px',
    color: '#e4fff9',
    border: `3px solid #e4fff9`,
    borderRadius: '10px',
    padding: '20px',
  },
  logoContainer: { width: '80px', margin: 'auto' },
  logo: { width: '80px' },
};

const synlabStyles = {
  container: {
    backgroundColor: colors.darkMain,
    alignItems: 'center',
    justifyContent: 'center',
  },
  main: {
    maxWidth: '500px',
    width: '80%',
    margin: 'auto',
    marginTop: '150px',
    color: '#003765',
    border: `3px solid #003765`,
    borderRadius: '10px',
    padding: '20px',
    backgroundColor: '#fff',
  },
  logoContainer: { width: '80px', margin: 'auto' },
  logo: { width: '80px' },
};

const styles = (): any =>
  featureGateInUse(featureGates.synlabCustomizations)
    ? synlabStyles
    : defaultStyles;

export default LoginView;
