import React from 'react';
import { Accordion } from 'lib/Accordion';

import { Address } from '../settings/types';
import Gateway from '../web/gateway';

const doorButtons = {
  routine: {
    label: 'J',
    text: 'RoutineSamples',
    value: 'Use door J',
  },
  covid: {
    label: 'M',
    text: 'CovidSamples',
    value: 'Use door M',
  },
};

const HeaderExpansionItem = ({
  address,
  transl,
}: {
  address: Address;
  transl: (label: string) => string;
}): JSX.Element => {
  return (
    <div
      className="container-fluid"
      style={styles.expansionContainer}
    >
      <div className="row">
        <div className="col-sm-6 no-padding-left">
          {address.shortAddress}
          <br />
          {address.postalCode} {address.city}
          <br />
          {address.notes === doorButtons.routine.value &&
            transl(doorButtons.routine.text)}
          {address.notes === doorButtons.covid.value &&
            transl(doorButtons.covid.text)}
        </div>
        <div className="col-sm-6 no-padding-left">
          {address.phone}
          <br />
          {address.email}
        </div>
      </div>
    </div>
  );
};

const getHeaderExpansionItem = (
  address: Address,
  transl: (label: string) => string
): JSX.Element => (
  <HeaderExpansionItem address={address} transl={transl} />
);

type Props = {
  values: Address | null;
  isExpanded: boolean;
  title: string;
  imgUrl: string;
  setValues: (
    arg: Address | ((prev: Address | null) => Address)
  ) => void;
  transl: (label: string) => string;
  hideSynlabEndAddressPanel: () => void;
  isAdmin: boolean;
};
export const SynlabEndAddressPanel = ({
  values,
  isExpanded,
  title,
  imgUrl,
  setValues,
  transl,
  hideSynlabEndAddressPanel,
  isAdmin,
}: Props): JSX.Element => {
  const headerExpansionItem =
    values !== null && !isExpanded
      ? getHeaderExpansionItem(values, transl)
      : null;

  const panelTitle =
    values !== null && !isExpanded ? values.title : title;

  const titleTextAlign =
    values !== null && !isExpanded ? 'left' : 'center';

  React.useEffect(() => {
    Gateway.get('/providercompany/synlab-end-address').then((data) =>
      setValues({ ...data, notes: doorButtons.covid.value })
    );
  }, [setValues]);

  return (
    <Accordion
      titleTextAlign={titleTextAlign}
      isExpanded={isExpanded}
      title={panelTitle}
      imgUrl={imgUrl}
      headerExpansionItem={headerExpansionItem}
      accordionExtraClassName={'accordion-synlab'}
    >
      <div className="container-fluid" style={{ padding: '15px' }}>
        <div className="row">
          <div className="col-md-4">
            <table>
              <tbody>
                <tr>
                  <td>
                    <label>
                      {transl('RecipientName')}&nbsp;&nbsp;
                    </label>
                  </td>
                  <td>
                    <span style={styles.address}>
                      {values?.title}
                    </span>
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>
                      {transl('ShortAddress')}&nbsp;&nbsp;
                    </label>
                  </td>
                  <td>
                    <span style={styles.address}>
                      {values?.shortAddress}
                    </span>
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>{transl('PostalCode')}&nbsp;&nbsp;</label>
                  </td>
                  <td>
                    <span style={styles.address}>
                      {values?.postalCode}
                    </span>
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>{transl('City')}&nbsp;&nbsp;</label>
                  </td>
                  <td>
                    <span style={styles.address}>{values?.city}</span>
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>{transl('PhoneNumber')}&nbsp;&nbsp;</label>
                  </td>
                  <td>
                    <span style={styles.address}>
                      {values?.phone}
                    </span>
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>{transl('Email')}&nbsp;&nbsp;</label>
                  </td>
                  <td>
                    <span style={styles.address}>
                      {values?.email}
                    </span>
                    <br />
                  </td>
                </tr>
                {isAdmin && (
                  <tr>
                    <td colSpan={2}>
                      <button
                        type="button"
                        onClick={(): void =>
                          hideSynlabEndAddressPanel()
                        }
                        className={'btn btn-primary'}
                      >
                        {transl('ChooseDifferentAddress')}
                      </button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="col-md-4">
            <div style={{ height: '20px' }}>
              <label>{transl('ChooseDoor')}:</label>
            </div>
            <div
              style={
                values?.notes === doorButtons.routine.value
                  ? styles.doorLabelButtonContainerActive
                  : styles.doorLabelButtonContainer
              }
              onClick={(): void => {
                if (values) {
                  setValues({
                    ...values,
                    notes: doorButtons.routine.value,
                  });
                }
              }}
            >
              <button
                style={
                  values?.notes === doorButtons.routine.value
                    ? styles.doorLabelButtonActive
                    : styles.doorLabelButton
                }
              >
                {doorButtons.routine.label}
              </button>
              <div style={styles.doorLabelButtonDescription}>
                {transl(doorButtons.routine.text)}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div style={{ height: '20px' }}></div>
            <div
              style={
                values?.notes === doorButtons.covid.value
                  ? styles.doorLabelButtonContainerActive
                  : styles.doorLabelButtonContainer
              }
              onClick={(): void => {
                if (values) {
                  setValues({
                    ...values,
                    notes: doorButtons.covid.value,
                  });
                }
              }}
            >
              <button
                style={
                  values?.notes === doorButtons.covid.value
                    ? styles.doorLabelButtonActive
                    : styles.doorLabelButton
                }
              >
                {doorButtons.covid.label}
              </button>
              <div style={styles.doorLabelButtonDescription}>
                {transl(doorButtons.covid.text)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Accordion>
  );
};

const doorLabelButtonStyle = {
  height: '80px',
  width: '80px',
  borderRadius: '5px',
  borderWidth: '3px',
  borderStyle: 'solid',
  fontSize: '30px',
  textAlign: 'center' as 'center',
  fontWeight: 'bold' as 'bold',
  marginRight: '20px',
  marginTop: '10px',
};

const doorLabelButtonContainerStyle = {
  fontSize: '1.2em',
  fontWeight: 'bold' as const,
  cursor: 'pointer',
};

const styles = {
  expansionContainer: {
    paddingLeft: '75px',
    paddingRight: '75px',
    margin: 0,
  },
  address: {
    color: '#003765',
    fontSize: '1.5em',
  },
  doorLabelButtonContainer: {
    ...doorLabelButtonContainerStyle,
    color: '#ccc',
  },
  doorLabelButton: {
    ...doorLabelButtonStyle,
    borderColor: '#ccc',
    color: '#ccc',
  },
  doorLabelButtonContainerActive: {
    ...doorLabelButtonContainerStyle,
    color: '#003765',
  },
  doorLabelButtonActive: {
    ...doorLabelButtonStyle,
    borderColor: '#003765',
    color: '#003765',
  },
  doorLabelButtonDescription: {
    display: 'inline-block',
    height: '80px',
    verticalAlign: 'middle',
  },
};
