import React from 'react';
import { Accordion } from 'lib/Accordion';
import colors from 'lib/colors';
import moment from 'lib/moment';
import { debounce } from 'lodash';

import {
  nowIsOutsideHelsinkiBusinessHours,
  validateExternalID,
} from './helpers';
import { PickupTimeSelector } from './PickupTimeSelector';
import {
  featureGateInUse,
  featureGates,
  getIsPincodeVerificationRequiredForCompany,
} from '../featureGates';

const range = (start: number, end: number): number[] => {
  const res: number[] = [];
  for (let i = start; i < end; i++) {
    res.push(i);
  }
  return res;
};

const HeaderExpansionItem = ({
  externalID,
  nofPackages,
  allowLeaveAtDoor,
  transl,
  pickupTime,
  returnToStartAfterComplete,
  skipPincodeVerification,
}: {
  externalID: string;
  nofPackages: number;
  allowLeaveAtDoor: boolean;
  transl: (label: string) => string;
  pickupTime: string | null;
  returnToStartAfterComplete: boolean;
  skipPincodeVerification: boolean;
}): JSX.Element => {
  return (
    <div
      className="container-fluid"
      style={styles.expansionContainer}
    >
      <div className="row">
        <div className="col-sm-12 no-padding-left">
          {transl('OrderNumber')}: {externalID}
          <br />
          {transl('NumberOfPackages')}: {nofPackages}
          {allowLeaveAtDoor && <br />}
          {allowLeaveAtDoor && transl('ContactlessDelivery')}
          {returnToStartAfterComplete && <br />}
          {returnToStartAfterComplete &&
            transl('ReturnToStartAddress')}
          {skipPincodeVerification && <br />}
          {skipPincodeVerification &&
            transl('SkipPincodeVerification')}
          {pickupTime && <br />}
          {pickupTime &&
            `${transl('EarliestPickupTime')} ${moment(
              pickupTime
            ).format('L')} ${moment(pickupTime).format('HH:mm')}`}
        </div>
      </div>
    </div>
  );
};

const getHeaderExpansionItem = (
  externalID: string,
  nofPackages: number,
  allowLeaveAtDoor: boolean,
  transl: (label: string) => string,
  pickupTime: string | null,
  returnToStartAfterComplete: boolean,
  skipPincodeVerification: boolean
): JSX.Element => (
  <HeaderExpansionItem
    externalID={externalID}
    nofPackages={nofPackages}
    allowLeaveAtDoor={allowLeaveAtDoor}
    transl={transl}
    pickupTime={pickupTime}
    returnToStartAfterComplete={returnToStartAfterComplete}
    skipPincodeVerification={skipPincodeVerification}
  />
);

type Props = {
  isExpanded: boolean;
  externalID: string;
  nofPackages: number;
  allowLeaveAtDoor: boolean;
  transl: (label: string) => string;
  setExternalID: (val: string) => void;
  setNofPackages: (val: number) => void;
  setAllowLeaveAtDoor: (val: boolean) => void;
  pickupTime: string | null;
  setPickupTime: (time: string | null) => void;
  packageType: 'normal' | 'pallet' | 'eurPallet' | 'finPallet';
  setPackageType: (
    val: 'normal' | 'pallet' | 'eurPallet' | 'finPallet'
  ) => void;
  serviceLevel: 'samehour' | 'sameday';
  setServiceLevel: (val: 'samehour' | 'sameday') => void;
  skipPincodeVerification: boolean;
  setSkipPincodeVerification: (val: boolean) => void;
  returnToStartAfterComplete: boolean;
  setReturnToStartAfterComplete: (val: boolean) => void;
  isColdDelivery: boolean;
  setIsColdDelivery: (val: boolean) => void;
  isCriticallyUrgent: boolean;
  setIsCriticallyUrgent: (val: boolean) => void;
};

export const OrderPanel = ({
  isExpanded,
  externalID,
  nofPackages,
  allowLeaveAtDoor,
  transl,
  setExternalID,
  setNofPackages,
  setAllowLeaveAtDoor,
  pickupTime,
  setPickupTime,
  packageType,
  setPackageType,
  serviceLevel,
  setServiceLevel,
  skipPincodeVerification,
  setSkipPincodeVerification,
  returnToStartAfterComplete,
  setReturnToStartAfterComplete,
  isColdDelivery,
  setIsColdDelivery,
  isCriticallyUrgent,
  setIsCriticallyUrgent,
}: Props): JSX.Element => {
  const [externalIDValid, setExternalIDValid] = React.useState(
    null as null | boolean
  );

  const headerExpansionItem =
    !!externalID && !isExpanded
      ? getHeaderExpansionItem(
          externalID,
          nofPackages,
          allowLeaveAtDoor,
          transl,
          pickupTime,
          returnToStartAfterComplete,
          getIsPincodeVerificationRequiredForCompany() &&
            skipPincodeVerification
        )
      : null;

  const titleTextAlign =
    !!externalID && !isExpanded ? 'left' : 'center';

  const performValidateExternalID = React.useCallback(
    debounce((val: string): void => {
      setExternalIDValid(null);
      validateExternalID(val).then(setExternalIDValid);
    }, 500),
    [setExternalIDValid]
  );

  React.useEffect(() => {
    setExternalIDValid(null);
    if (externalID) performValidateExternalID(externalID);
    return (): void => {
      // Intentionally empty
    };
  }, [performValidateExternalID, externalID]);

  const externalIDChanged = (newVal: string): void => {
    setExternalID(newVal.split(' ').join(''));
  };

  return (
    <Accordion
      titleTextAlign={titleTextAlign}
      isExpanded={isExpanded}
      title={transl('Order')}
      imgUrl={'/img/info.png'}
      headerExpansionItem={headerExpansionItem}
      accordionExtraClassName={
        featureGateInUse(featureGates.synlabCustomizations)
          ? 'accordion-synlab'
          : ''
      }
    >
      <div className="container-fluid" style={{ padding: '15px' }}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="orderpanel-externalid">
                {transl('OrderNumber')}
              </label>
              <input
                type="text"
                value={externalID}
                className="form-control"
                id="orderpanel-externalid"
                onChange={(e): void =>
                  externalIDChanged(e.target.value)
                }
              />
              {externalIDValid === true && (
                <div style={styles.successMsg}>
                  <i className="fa fa-check"></i>
                  {transl('OrderNumberValidMsg')}
                </div>
              )}
              {externalIDValid === false && (
                <div style={styles.errorMsg}>
                  <i className="fa fa-times"></i>
                  {transl('OrderNumberInvalidMsg')}
                </div>
              )}
              {externalIDValid === null && !!externalID && (
                <div>
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
              )}
            </div>
            <div className="form-group">
              <label>
                {transl(
                  packageType === 'normal'
                    ? 'NumberOfPackages'
                    : 'NumberOfPallets'
                )}
              </label>
              <br />
              <button
                style={
                  nofPackages === 1
                    ? styles.parcelButtonActive
                    : styles.parcelButton
                }
                onClick={(): void => setNofPackages(1)}
              >
                1
              </button>
              <button
                style={
                  nofPackages === 2
                    ? styles.parcelButtonActive
                    : styles.parcelButton
                }
                onClick={(): void => setNofPackages(2)}
              >
                2
              </button>
              <button
                style={
                  nofPackages === 3
                    ? styles.parcelButtonActive
                    : styles.parcelButton
                }
                onClick={(): void => setNofPackages(3)}
              >
                3
              </button>
              <button
                style={
                  nofPackages >= 4
                    ? styles.parcelButtonActive
                    : styles.parcelButton
                }
                onClick={(): void => setNofPackages(4)}
              >
                <small>{transl('MoreThan3')}</small>
              </button>
            </div>
            {nofPackages > 3 && (
              <div className="form-group">
                <label>{transl('NumberOfPackages')}</label>
                <select
                  className="form-control"
                  value={nofPackages.toString()}
                  onChange={(e): void => {
                    setNofPackages(parseInt(e.target.value, 10));
                  }}
                >
                  {range(4, 41).map((item) => {
                    return (
                      <option
                        key={item.toString()}
                        value={item.toString()}
                      >
                        {item.toString()}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>
          <div className="col-md-6">
            <div className="row">
              {featureGateInUse(
                featureGates.showPalletSendingOptionsInOrderPanel
              ) && (
                <div className="col-md-6">
                  <div className="form-group">
                    <label>{transl('DeliveryType')}</label>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          name="package-type-radio"
                          checked={packageType === 'normal'}
                          onChange={(e): void => {
                            if (e.target.checked) {
                              setPackageType('normal');
                            }
                          }}
                        />
                        {transl('Normal')}
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          name="package-type-radio"
                          checked={packageType === 'pallet'}
                          onChange={(e): void => {
                            if (e.target.checked) {
                              setPackageType('pallet');
                            }
                          }}
                        />
                        {transl('Pallet')}
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          name="package-type-radio"
                          checked={packageType === 'eurPallet'}
                          onChange={(e): void => {
                            if (e.target.checked) {
                              setPackageType('eurPallet');
                            }
                          }}
                        />
                        {transl('EurPallet')}
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          name="package-type-radio"
                          checked={packageType === 'finPallet'}
                          onChange={(e): void => {
                            if (e.target.checked) {
                              setPackageType('finPallet');
                            }
                          }}
                        />
                        {transl('FinPallet')}
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {featureGateInUse(
                featureGates.showSamedayOrdersInOrderPanel
              ) && (
                <div className="col-md-6">
                  <div className="form-group">
                    <label>{transl('ServiceLevel')}</label>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          name="service-level-radio"
                          checked={serviceLevel === 'samehour'}
                          onChange={(e): void => {
                            if (e.target.checked) {
                              setServiceLevel('samehour');
                            }
                          }}
                        />
                        {transl('ServiceLevelSamehour')}
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          name="service-level-radio"
                          checked={serviceLevel === 'sameday'}
                          onChange={(e): void => {
                            if (e.target.checked) {
                              setServiceLevel('sameday');
                            }
                          }}
                        />
                        {transl('ServiceLevelSameday')}
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!featureGateInUse(featureGates.synlabCustomizations) && (
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={allowLeaveAtDoor}
                    onChange={(e): void => {
                      const isChecked = (e.target as any).checked;
                      setAllowLeaveAtDoor(isChecked);
                      if (
                        isChecked &&
                        getIsPincodeVerificationRequiredForCompany()
                      ) {
                        setSkipPincodeVerification(true);
                      }
                    }}
                  />
                  &nbsp;&nbsp;
                  {transl('ContactlessDelivery')}
                </label>
              </div>
            )}
            {!featureGateInUse(featureGates.synlabCustomizations) &&
              getIsPincodeVerificationRequiredForCompany() && (
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      checked={skipPincodeVerification}
                      onChange={(e): void => {
                        const isChecked = (e.target as any).checked;
                        setSkipPincodeVerification(isChecked);

                        if (!isChecked) {
                          setAllowLeaveAtDoor(false);
                        }
                      }}
                    />
                    &nbsp;&nbsp;
                    {transl('SkipPincodeVerification')}
                  </label>
                </div>
              )}
            {!featureGateInUse(featureGates.synlabCustomizations) && (
              <React.Fragment>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      checked={returnToStartAfterComplete}
                      onChange={(e): void => {
                        const isChecked = (e.target as any).checked;
                        setReturnToStartAfterComplete(isChecked);
                      }}
                    />
                    &nbsp;&nbsp;
                    {transl('ReturnToStartAddress')}
                  </label>
                </div>
                {returnToStartAfterComplete && (
                  <p className="text-warning">
                    {transl('ReturnToStartAddressWarningMsg')}
                  </p>
                )}
              </React.Fragment>
            )}
            {featureGateInUse(
              featureGates.showColdDeliveryOption
            ) && (
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={isColdDelivery}
                    onChange={(e): void => {
                      const isChecked = (e.target as any).checked;
                      setIsColdDelivery(isChecked);
                    }}
                  />
                  &nbsp;&nbsp;
                  {transl('ColdDelivery')}
                </label>
              </div>
            )}
            {featureGateInUse(featureGates.teliaCustomizations) && (
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={isCriticallyUrgent}
                    onChange={(e): void => {
                      const isChecked = (e.target as any).checked;
                      setIsCriticallyUrgent(isChecked);
                    }}
                    disabled={nowIsOutsideHelsinkiBusinessHours()}
                  />
                  &nbsp;&nbsp;
                  {transl('IsCriticallyUrgent')}
                </label>
              </div>
            )}
            {serviceLevel === 'samehour' && (
              <PickupTimeSelector
                pickupTime={pickupTime}
                setPickupTime={setPickupTime}
                transl={transl}
              />
            )}
          </div>
        </div>
      </div>
    </Accordion>
  );
};

const parcelNumberButtonStyle = {
  height: '80px',
  width: '80px',
  borderRadius: '5px',
  borderWidth: '3px',
  borderStyle: 'solid',
  fontSize: '30px',
  textAlign: 'center' as 'center',
  fontWeight: 'bold' as 'bold',
  marginRight: '20px',
  marginTop: '10px',
};

const styles = {
  expansionContainer: {
    paddingLeft: '75px',
    paddingRight: '75px',
    margin: 0,
  },
  errorMsg: {
    color: colors.danger,
  },
  successMsg: {
    color: colors.success,
  },
  parcelButton: {
    ...parcelNumberButtonStyle,
    borderColor: '#ccc',
    color: '#ccc',
  },
  parcelButtonActive: {
    ...parcelNumberButtonStyle,
    borderColor: colors.main,
    color: colors.main,
  },
};
